/* eslint-disable @typescript-eslint/ban-ts-comment */
import { forwardRef } from "react";

import Input from "#components/base/Input/Input";
import Text from "#components/base/Text/Text";
import { flex, layout } from "#styles";

import type { Component, Props } from "./InputField.types";

const InputField: Component = forwardRef(
  <T extends React.ElementType = "input">(
    {
      className,
      errors,
      label,
      name,
      hidden,
      "aria-label": ariaLabel,
      ...otherProps
    }: Props<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const hasError = !!errors?.[name];
    return (
      <div
        style={hidden ? { display: "none" } : {}}
        className={className}
        css={flex.flexStartVertical}
      >
        {label && (
          <Text as="label" htmlFor={name}>
            {label}
          </Text>
        )}

        <div css={[flex.flexStartVertical, layout.gap(4)]}>
          {/* @ts-ignore */ ""}
          <Input
            error={hasError}
            name={name}
            ref={ref}
            {...otherProps}
            aria-label={ariaLabel}
          />

          {hasError && (
            <Text color="textNegative" variant="body2">
              {errors?.[name]?.message}
            </Text>
          )}
        </div>
      </div>
    );
  },
);

export default InputField;
