/* Create Application Utility
 * This handler, or portion of a handler, has
 * rather a lot of side effects. We don't want
 * them to get out of sync, so this utility
 * exists.
 * MPR, 2023/1/30: I am quite sure this is not the right
 * place for this, but I couldn't think of anywhere better
 * immediately. Feel free to move this file if someplace
 * sticks out at you.
 */

import noop from "lodash/noop";
import { NextRouter } from "next/router";

import LOSAPIClient from "#api.los/client";
import {
  Partner,
  TrackingPayload,
  VehiclePurchaseSource,
} from "#api.los/client.types";
import { LoanType } from "#components/partial/ApplyPage/ApplyPage.types";
import {
  ApplyContext,
  getCanCreateApplication,
} from "#components/provider/ProvidersOrchestration/Apply";
import {
  getErrorMessage,
  tenetAlert,
  trackedErrorAlert,
} from "#util/alerts/alerts";
import { getDealerInfo } from "#v2-components/pages/order-info/order-info-utils";

export const stripVehicleQueryParams = async (nextRouter: NextRouter) => {
  const { query } = nextRouter;
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    mileage,
    salePriceCents,
    amountFinanced,
    usedStatus,
    make,
    model,
    year,
    trim,
    vin,
    msrp,
    pickupDate,
    orderNumber,
    purchaseSource,
    vinMMYTMatch,
    ...rest
  } = query;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  await nextRouter.replace({ query: rest });
};

const createApplicationWithSideEffects = async ({
  partner,
  setLoading,
  haltUserRequests,
  haltApplicantRequests,
  setApplicationId,
  nextRouter,
  refreshApplication,
  errorMessage,
  currentApplicationId,
  refreshInit,
  backupVehicle,
  applyContext,
}: {
  partner: Partner | undefined;
  setLoading: (loading: boolean) => void;
  haltUserRequests: (halt: boolean) => void;
  haltApplicantRequests: (halt: boolean) => void;
  setApplicationId: (appId: string) => void;
  nextRouter: NextRouter;
  refreshApplication: () => Promise<void>;
  errorMessage: string;
  currentApplicationId: string;
  refreshInit: () => void;
  backupVehicle?: {
    year: number;
    make: string;
    model: string;
    trim: string;
  };
  applyContext: ApplyContext;
}) => {
  const existingQuery = nextRouter.query;
  try {
    setLoading(true);
    haltUserRequests(false);
    // Wall for checking definition of applyContext's required vars

    if (!getCanCreateApplication(applyContext)) {
      console.warn("Error creating application"); // eslint-disable-line no-console
      tenetAlert(
        "Please select a loan type and use for your vehicle before proceeding",
      );
      setLoading(false);
      // Take user back to the apply screen
      nextRouter.push("/apply");
      return false;
    }

    const { response, error } =
      await LOSAPIClient.application.createApplication({
        type: applyContext.loanType ?? LoanType.Purchase,
        draftId:
          applyContext.loanType === LoanType.Refinance
            ? currentApplicationId
            : undefined,
        partner,
        usage: applyContext.use,
        eligibilityData: applyContext.hostingData,
        loanClass: applyContext.loanClass,
      });
    if (error) {
      console.warn(error); // eslint-disable-line no-console
      trackedErrorAlert(
        getErrorMessage(error),
        "CREATE_APPLICATION_ERROR",
        undefined,
        "Error creating application, please try again",
        true,
      );
      setLoading(false);
      return false;
    }
    applyContext.reset();
    haltApplicantRequests(false);
    // Clear apply context for the next time the user goes to /apply
    if (!response?.body?.data.id) {
      throw new Error("failed to create application: ID is undefined");
    }
    const applicationId = response.body.data.id;
    setApplicationId(applicationId);
    const dealerInfo = getDealerInfo();
    if (dealerInfo) {
      const { error: dealerError } =
        await LOSAPIClient.application.postDealershipInfo(
          applicationId,
          dealerInfo,
        );

      if (dealerError) {
        // this is ok to continue
        // eslint-disable-next-line no-console
        console.error("12293d2c-3bc6-40c5-b8b0-90ed2c999fe4", dealerError);
      } else {
        LOSAPIClient.tracking.post({
          event: "DEALERSHIP_INFO_COMPLETE",
          details: { applicationId },
        });
      }
    }

    const purchaseSource = Object.keys(VehiclePurchaseSource).includes(
      existingQuery?.purchaseSource as string,
    )
      ? (existingQuery?.purchaseSource as string)
      : undefined;

    if (existingQuery?.vin || existingQuery?.make) {
      const { error: createError } =
        await LOSAPIClient.application.vehicle.create(applicationId, {
          usedStatus: existingQuery?.usedStatus as string,
          salePriceCents: Number(existingQuery?.salePriceCents || 0),
          mileage: Number(existingQuery?.mileage || 0),
          purchaseSource,
          vin: (existingQuery?.vin as string) || undefined,
          ymmt:
            !existingQuery?.trim && !existingQuery?.year && backupVehicle
              ? { ...backupVehicle }
              : {
                  make: (existingQuery?.make as string) || "",
                  model: (existingQuery?.model as string) || "",
                  year: Number(existingQuery?.year || 0),
                  trim: (existingQuery?.trim as string) || "",
                },
        });
      if (createError) {
        console.warn(createError); // eslint-disable-line no-console
        tenetAlert(errorMessage);
        setLoading(false);
        return false;
      }

      if (existingQuery.pickupDate && existingQuery.purchaseSource) {
        const { error: updateError } =
          await LOSAPIClient.application.vehicle.update(applicationId, {
            pickupDate: String(existingQuery.pickupDate),
            purchaseSource:
              String(existingQuery.purchaseSource) === VehiclePurchaseSource.OEM
                ? VehiclePurchaseSource.OEM
                : VehiclePurchaseSource.Dealer,
          });

        if (updateError) {
          // this is ok to continue
          // eslint-disable-next-line no-console
          console.error("e0a4947b-6274-4962-97af-f560a3e81835", error);
        }
      }
      if (existingQuery.amountFinanced) {
        try {
          const principalQuery = existingQuery.amountFinanced as string;
          const principalAmountCents = parseInt(principalQuery, 10);
          // leaving this error unhandled as it shouldn't happen.
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { error: _buildOfferError } =
            await LOSAPIClient.application.updateTerms({
              applicationId: response.body.data.id,
              principalAmountCents,
            });
        } catch (e) {
          noop();
        }
      }

      const trackingPayload: TrackingPayload = {
        event: "APPLICATION_VEHICLE_CREATED",
        details: {
          applicationId,
          purchaseSource: existingQuery.purchaseSource,
          deliveryDate: existingQuery.pickupDate,
          vinMMYTMatch: existingQuery.vinMMYTMatch,
          orderNumber: existingQuery.orderNumber,
        },
      };
      await LOSAPIClient.tracking.post(trackingPayload);

      await stripVehicleQueryParams(nextRouter);
      await refreshApplication();
      refreshInit();
    }
    console.log("application created"); // eslint-disable-line no-console

    // Set appId in query params
    /** MT 3/10/23 - next router.query returns a { url: 'turo' } for turo or partner apps
     * We need to account for this when using nextRouter.replace to keep 'turo' in the url
     * and not pass it in with the query params
     */
    await nextRouter.replace({
      query: { id: response.body.data.id, url: existingQuery.url },
    });

    try {
      await LOSAPIClient.user.updateReferrer();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("failed to update referrer");
    }
  } catch (e) {
    const errMessage = getErrorMessage(e);
    trackedErrorAlert(
      errMessage,
      "CREATE_APPLICATION_ERROR",
      undefined,
      "",
      false,
    );
    setLoading(false);
    return false;
  }
  setLoading(false);
  return true;
};

export default createApplicationWithSideEffects;
