import { useState } from "react";

import LOSAPIClient from "#api.los/client";
import { useAddonContext } from "#components/provider/AddonProvider/AddonProvider";
import { useApplicationContext } from "#components/provider/ApplicationProvider/ApplicationProvider";
import { getErrorMessage, trackedErrorAlert } from "#util/alerts/alerts";

import LoadingState from "../LoadingState/LoadingState";
import PaymentPreferences from "../PaymentPreferences/PaymentPreferences";
import s from "./PaymentPreferencesForm.module.scss";

const ERR_MESSAGE = "An error occured while storing your payment preference.";

const getOnSubmit = (
  setLoading: (arg: boolean) => void,
  refreshApplication: () => Promise<void>,
  currentApplicationId?: string,
) => {
  const onSubmit = async (hasSelectedAutopay: boolean) => {
    if (!currentApplicationId) return false;
    try {
      setLoading(true);
      const { response } =
        await LOSAPIClient.application.payments.postAutopayPreference(
          currentApplicationId,
          hasSelectedAutopay,
        );
      if (response?.body?.data) {
        window.analytics?.track(
          hasSelectedAutopay
            ? `USER_SELECTED_AUTOPAY`
            : `USER_SELECTED_NO_AUTOPAY`,
        );
        await refreshApplication();
      }
      await LOSAPIClient.tracking.post({
        event: "PAYMENT_PREFERENCE_COMPLETE",
        details: { applicationId: currentApplicationId },
      });
      setLoading(false);
      return true;
    } catch (err) {
      trackedErrorAlert(
        getErrorMessage(err),
        undefined,
        undefined,
        ERR_MESSAGE,
      );
      setLoading(false);
      return false;
    }
  };
  return onSubmit;
};

export const PaymentPreferencesForm = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const {
    state,
    applicationFunctions,
    loading: applicationLoading,
  } = useApplicationContext();
  const { addonFunctions, loading: addonLoading } = useAddonContext();
  const { applicationId } = state;

  const refresh = async () => {
    const r = [];
    r.push(applicationFunctions.refreshApplication());
    r.push(addonFunctions.refreshAddons());
    await Promise.all(r);
  };

  const allLoading = addonLoading.all || applicationLoading.all || loading;

  const onContinue = async (hasSelectedAutopay: boolean) => {
    const onSubmit = getOnSubmit(setLoading, refresh, applicationId);
    const ok = await onSubmit(hasSelectedAutopay);
    if (ok) {
      closeModal();
    }
  };

  if (allLoading) {
    <div className={s.loadingContainer}>
      <LoadingState />
    </div>;
  }

  return (
    // <div className={s.incomeModal}>
    <PaymentPreferences onSubmit={onContinue} />
    // </div>
  );
};
