import LOSClient from "#api.los/client";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { WarrantyPlanDecisionInputDto } from "#components/provider/AddonProvider/AddonTypes";
import { restPaths } from "#constants/restPaths";

import { HTTPPostWarrantyBody, LOSAPIResponse } from "./client.types";

/**
 * Retrieves addons from an application
 * @param applicationId application to specify for warranty
 * @param payload payload for posting to warranty
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const postWarranty: (
  applicationId: string,
  payload: WarrantyPlanDecisionInputDto,
) => LOSAPIResponse<HTTPPostWarrantyBody> = async (
  applicationId: string,
  payload: WarrantyPlanDecisionInputDto,
) => {
  LOSClient.tracking.post({
    event: `${payload.elected ? "SELECTED" : "DECLINED"}_WARRANTY_ADDON`,
    details: { applicationId },
  });
  const response = await makeRequest<HTTPPostWarrantyBody>(
    HTTPMethod.POST,
    restPaths.application(applicationId).warranty.post,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default postWarranty;
