import classNames from "classnames";
import { useRouter } from "next/router";
import { useEffect } from "react";

import LoadingState from "#components/partial/LoadingState/LoadingState";
import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";
import { useRouterContext } from "#components/provider/Router";
import { allUSStates as usStates } from "#constants/allUSStates";
import atomics from "#styles/atomic.module.scss";
import { stripVehicleQueryParams } from "#util/createApplication";
import { parseCentsFromCurrencyInputString } from "#util/number/number";
import { Page } from "#util/pageNavigationHelpers";
import { Card } from "#v2-components/molecules/Card/Card";
import { CardVariant } from "#v2-components/molecules/Card/Card.types";

import s from "./ApplyPage.module.scss";
import {
  HostingPlatform,
  LoanClassV2,
  LoanType,
  VehicleUsage,
} from "./ApplyPage.types";
import BusinessEligibility from "./steps/BusinessEligibility";
import HostingEligibilityStep, {
  HostingEligibilitySubmitData,
} from "./steps/HostingEligibility";
import Ineligible from "./steps/Ineligible";
import LoanClassStep from "./steps/LoanClass";
import LoanTypeStep from "./steps/LoanType";
import Platform from "./steps/Platform";
import UseStep from "./steps/Use";
import { useGetNavigationFn } from "./useGetNavigationFn";

export enum StepEnum {
  LOAN_TYPE = "LOAN_TYPE",
  LOAN_CLASS = "LOAN_CLASS",
  USE = "USE",
  HOSTING_ELIGIBILITY = "HOSTING_ELIGIBILITY",
  BUSINESS_ELIGIBILITY = "BUSINESS_ELIGIBILITY",
  INELIGIBLE = "INELIGIBLE",
  PLATFORM = "PLATFORM",
}

export const Option = ({
  title,
  subtitle,
  onClick,
  selected = false,
  ...otherProps
}: {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  onClick: () => void;
  selected?: boolean;
}) => {
  return (
    <button
      type="button"
      className={s.option}
      onClick={onClick}
      {...otherProps}
    >
      <Card variant={CardVariant.LIGHT_PURPLE} isActive={!!selected}>
        <h4>{title}</h4>
        {subtitle && <p>{subtitle}</p>}
      </Card>
    </button>
  );
};
const checkSupportedState = (submittedState: string | undefined) => {
  const state = usStates.find(
    (usState) => usState.abbreviation === submittedState?.toUpperCase(),
  );
  if (
    submittedState &&
    state?.supportsDirectPurchase &&
    state?.supportsRefinance
  ) {
    return true;
  }
  return false;
};
// For Hosting Eligibility page
export const getIsEligible = (submitData: HostingEligibilitySubmitData) => {
  const hasAtLeastOneVehicle =
    (submitData.numEVs || 0) + (submitData.numICEs || 0) > 0;
  const meetsMinRevenueRequirement =
    parseCentsFromCurrencyInputString(submitData.avgRevenue || "") > 120000; // In Cents
  const meetsStateRequirement = checkSupportedState(submitData?.state);
  // add a check to see if the state submitted is a valid state

  return (
    hasAtLeastOneVehicle && meetsMinRevenueRequirement && meetsStateRequirement
  );
};

const ApplyPage = () => {
  const nextRouter = useRouter();
  const { navigateTo, isNavigating } = useRouterContext();
  // const [step, setStep] = useState<StepEnum>(StepEnum.LOAN_CLASS);
  const applyContext = useApplyContext();

  const [step] = applyContext.navigation.history;
  useEffect(() => {
    /*
     * reset apply context/query params when page navigates to
     * start of the flow to remove previous applications details
     * */
    if (step === StepEnum.LOAN_CLASS) {
      applyContext.reset();
      stripVehicleQueryParams(nextRouter);
    }
  }, [step]);

  const onSetLoanClass = (loanClass: LoanClassV2) => {
    window?.analytics?.track("SUBMIT_LOAN_CLASS", {
      loanClass,
    });
    applyContext.setProvider({
      ...applyContext,
      loanClass,
    });
    if (loanClass === LoanClassV2.Commercial) {
      return applyContext.navigation.addStep(StepEnum.BUSINESS_ELIGIBILITY);
    }
    return applyContext.navigation.addStep(StepEnum.LOAN_TYPE);
  };
  const onSetLoanType = (loanType: LoanType) => {
    window?.analytics?.track("SUBMIT_LOAN_TYPE", {
      loanType,
    });
    applyContext.setProvider({
      ...applyContext,
      loanType,
    });
    if (applyContext.loanClass === LoanClassV2.Commercial) {
      applyContext.navigation.addStep(StepEnum.PLATFORM);
    }
    if (applyContext.loanClass === LoanClassV2.Individual) {
      applyContext.navigation.addStep(StepEnum.USE);
    }
  };
  const onSetUse = (use: VehicleUsage) => {
    applyContext.setProvider({
      ...applyContext,
      use,
    });
    if (use === VehicleUsage.Carsharing) {
      return applyContext.navigation.addStep(StepEnum.HOSTING_ELIGIBILITY);
    }

    return sendToCorrectFlow();
  };

  const sendToCorrectFlow = () => {
    if (applyContext.loanType === LoanType.Purchase) {
      navigateTo(Page.INTENT, {
        product: "new-loan",
      });
    }
    if (applyContext.loanType === LoanType.Refinance) {
      navigateTo(Page.REFI_VEHICLE_INFO_FORM, {
        product: "refinance",
      });
    }
  };

  const onSubmitHostingEligibility = (
    submitData: HostingEligibilitySubmitData,
  ): void => {
    const isEligible = getIsEligible(submitData);
    const newProvider = {
      ...applyContext,
      hostingData: submitData,
      isEligibleForHosting: isEligible,
      use: VehicleUsage.Carsharing,
    };
    applyContext.setProvider(newProvider);

    if (!isEligible) {
      return applyContext.navigation.addStep(StepEnum.INELIGIBLE);
    }
    // If eligible, and is personal flow, submit (personal?)
    if (isEligible && submitData.majorityOwner === undefined) {
      applyContext.navigation.addStep(StepEnum.HOSTING_ELIGIBILITY);
      return sendToCorrectFlow();
    }
    // if (isEligible && submitData.majorityOwner !== undefined) {
    // Commercial flow
    return applyContext.navigation.addStep(StepEnum.LOAN_TYPE);
    // }
  };

  const onSubmitPlatform = (platform: HostingPlatform) => {
    applyContext.setProvider({
      ...applyContext,
      hostingData: {
        ...applyContext.hostingData,
        platform,
      },
    });
    applyContext.navigation.addStep(StepEnum.PLATFORM);
    return sendToCorrectFlow();
  };

  // Set correct step based on what's defined in applyContext
  const navigate = useGetNavigationFn({
    setStep: applyContext.navigation.addStep,
    sendToCorrectFlow,
  });

  useEffect(() => {
    navigate();
  }, []);

  const renderStep = () => {
    switch (true) {
      case step === StepEnum.LOAN_CLASS:
        return <LoanClassStep onSubmit={onSetLoanClass} />;
      case step === StepEnum.LOAN_TYPE:
        return <LoanTypeStep onSubmit={onSetLoanType} />;
      case step === StepEnum.USE:
        return <UseStep onSubmit={onSetUse} />;
      case step === StepEnum.HOSTING_ELIGIBILITY:
        return <HostingEligibilityStep onSubmit={onSubmitHostingEligibility} />;
      case step === StepEnum.INELIGIBLE:
        return <Ineligible />;
      case step === StepEnum.BUSINESS_ELIGIBILITY:
        return <BusinessEligibility onSubmit={onSubmitHostingEligibility} />;
      case step === StepEnum.PLATFORM:
        return <Platform onSubmit={onSubmitPlatform} />;
      default:
        return <LoadingState />;
    }
  };

  if (isNavigating) {
    return (
      <main className={classNames(atomics.relative, atomics.flexGrow)}>
        <LoadingState />
      </main>
    );
  }

  return <div className={s.container}>{renderStep()}</div>;
};

export default ApplyPage;
