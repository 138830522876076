import classNames from "classnames";
import React, { useState } from "react";
import { useController } from "react-hook-form";

import Checkmark from "#components/base/Icon/icons/checkmark.svg";
import ErrorBoundary from "#v2-components/organisms/ErrorBoundary";

import s from "./Card.module.scss";
import { CardProps, CardVariant, ControlledCardProps } from "./Card.types";

export const ControlledCheckboxCard: React.FC<ControlledCardProps & CardProps> =
  ({
    active,
    disabled,
    children,
    variant,
    inputProps,
    control,
    error,
    ...otherProps
  }) => {
    const { field } = useController({ control, name: inputProps?.name || "" });
    const [isActive, setIsActive] = useState(
      (field.value as boolean) || active,
    );

    const setValue = (value: boolean) => {
      if (!disabled) {
        setIsActive(value);
        field.onChange(value);
      }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(!!event.target.value);
      if (otherProps.onChange) otherProps.onChange(event);
    };

    const toggleCard = () => {
      setValue(!isActive);
      const event = {
        target: {
          checked: !isActive,
          value: !isActive,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      if (otherProps.onChange) otherProps.onChange(event);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.code === "Enter") toggleCard();
    };

    const cardClassName =
      variant && isActive
        ? classNames(s[error ? CardVariant.LIGHT_RED : variant])
        : classNames(s[`card_${error ? CardVariant.LIGHT_RED : variant}`]);

    return (
      <Card
        containerProps={{
          role: "button",
          tabIndex: 0,
          onClick: () => toggleCard(),
          onKeyDown: handleKeyDown,
          className: cardClassName,
          ...otherProps,
        }}
      >
        <>
          <div>
            <span>
              <input
                type="checkbox"
                checked={isActive}
                className={isActive ? s.checkedBox : s.box}
                {...inputProps}
                onChange={onChange}
              />
              {isActive && <Checkmark />}
            </span>
          </div>
          <ErrorBoundary>{children}</ErrorBoundary>
        </>
      </Card>
    );
  };

export const Card: React.FC<CardProps> = ({
  isActive,
  children,
  variant,
  containerProps,
  checkbox,
  centerAligned,
}) => {
  const baseCardClassName = centerAligned ? "centerAligned_" : "";
  const cardClassName =
    variant && isActive
      ? classNames(s[`${baseCardClassName}${variant}`])
      : classNames(s[`${baseCardClassName}card_${variant || ""}`]);

  return (
    <div className={cardClassName} {...containerProps}>
      {checkbox && (
        <div>
          <input type="checkbox" className={isActive ? s.checkedBox : s.box} />
          <span>{isActive && <Checkmark />}</span>
        </div>
      )}
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
};
