import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";

import CurrencyInputField from "#components/base/CurrencyInputField/CurrencyInputField";
import DropdownField from "#components/base/DropdownField/DropdownField";
import { Getaround, Info, Turo } from "#components/base/Icon/Icon";
import InputField from "#components/base/InputField/InputField";
import Text from "#components/base/Text/Text";
import Tooltip from "#components/base/Tooltip/Tooltip";
import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";
import { allUSStates } from "#constants/allUSStates";
import { currencyValueValidator } from "#util/validators/validators";
import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import { Option, StepEnum } from "../ApplyPage";
import styles from "../ApplyPage.module.scss";
import { HostingPlatform } from "../ApplyPage.types";

export interface HostingEligibilitySubmitData {
  platform?: HostingPlatform;
  numEVs?: number;
  numICEs?: number;
  months?: number;
  years?: number;
  majorityOwner?: boolean;
  avgRevenue?: string;
  state?: string;
}

const getFormConfig = () => {
  return {
    defaultValues: {
      numEVs: undefined,
      numICEs: undefined,
      months: undefined,
      years: undefined,
      avgRevenue: undefined,
      state: undefined,
    },
    resolver: yupResolver(
      object({
        // EV: undefined are transformed to hide error validation error messsages from yupResolver
        // setting them to null raises errors if the fields are clicked into
        state: string().required("Please select a state"),
        numEVs: number()
          .optional()
          .min(0, "Number must be at least 0")
          .max(100)
          .transform((value: number | undefined) => transformedValue(value)),
        numICEs: number()
          .optional()
          .min(0, "Number must be at least 0")
          .transform((value: number | undefined) => transformedValue(value)),
        months: number()
          .optional()
          .min(0, "Number must be at least 0")
          .max(11, "Invalid number of months")
          .transform((value: number | undefined) => transformedValue(value)),
        years: number()
          .min(0, "Number must be at least 0")
          .optional()
          .transform((value: number | undefined) => transformedValue(value)),
        avgRevenue: currencyValueValidator(0).required(),
      }),
    ),
  };
};

const transformedValue = (value: number | undefined) =>
  Number.isNaN(value) || value === undefined ? 0 : value;

// .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)

export const HostingEligibilityForm = ({
  onSubmit,
  platform,
}: {
  onSubmit: (args: HostingEligibilitySubmitData) => void;
  platform?: HostingPlatform;
}) => {
  const formConfig = getFormConfig();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<HostingEligibilitySubmitData>(formConfig);

  return (
    <form className={styles.platformForm}>
      <div className={styles.revenueContainer}>
        <h2 style={{ fontSize: 16 }}>Where is your business is registered?</h2>
        <DropdownField
          className={styles.stateDropdown}
          id="business-registered-state-input"
          {...register("state")}
          placeholder="Select state"
          errors={errors}
          options={allUSStates.map((state) => {
            return {
              value: state.abbreviation,
              text: state.name,
            };
          })}
          aria-label="business registered state"
        />
      </div>

      <h3>
        How many vehicles are you currently hosting on{" "}
        {platform ?? "Turo or Getaround"}?
      </h3>
      <div className={styles.fieldsContainer}>
        <InputField
          placeholder="0"
          errors={errors}
          label="Electric or plugin hybrids"
          type="number"
          id="num-evs-input"
          aria-label="numEVs"
          {...register("numEVs")}
          className={styles.inputField}
        />
        <InputField
          placeholder="0"
          errors={errors}
          label="Gasoline vehicles"
          type="number"
          id="num-ices-input"
          aria-label="numICEs"
          {...register("numICEs")}
          className={styles.inputField}
        />
      </div>
      <h3>How long have you been hosting?</h3>
      <div className={styles.fieldsContainer}>
        <InputField
          placeholder="0"
          errors={errors}
          label="Years"
          type="number"
          id="years-input"
          aria-label="years"
          {...register("years")}
          className={styles.inputField}
        />
        <InputField
          placeholder="0"
          errors={errors}
          label="Months"
          type="number"
          id="months-input"
          aria-label="months"
          {...register("months")}
          className={styles.inputField}
        />
      </div>
      <CurrencyInputField
        label={
          <span style={{ display: "flex", alignItems: "center" }}>
            <h3 className={styles.currencyInputLabel}>
              What is your average monthly revenue?
              <Tooltip
                trigger={
                  <Info
                    color="uiPrimary"
                    size={16}
                    style={{ marginLeft: 12, marginTop: 12 }}
                  />
                }
              >
                <Text variant="body2">
                  Provide a monthly average of the combined revenue from all
                  vehicles you host.
                </Text>
              </Tooltip>
            </h3>
          </span>
        }
        id="avg-revenue-input"
        aria-label="avgRevenue"
        {...register("avgRevenue")}
        errors={errors}
        control={control}
        placeholder="$0.00"
        className={styles.revenueField}
      />
      <Button
        type="submit"
        onClick={handleSubmit((data) => {
          window?.analytics?.track("HOSTING_ELIGIBILITY_SUBMIT", {
            ...data,
            platform,
          });
          onSubmit({
            ...data,
            platform,
          });
        })}
        variant={ButtonVariant.DARK_FILLED}
        label="Continue"
        className={styles.submitButton}
        data-cy="continue"
      />
    </form>
  );
};

export const defaultPlatformOptions = [
  {
    title: "Getaround",
    logo: <Getaround className={styles.icon} />,
    platform: HostingPlatform.Getaround,
  },
  {
    title: "Turo",
    logo: <Turo className={styles.icon} />,
    platform: HostingPlatform.Turo,
  },
  {
    title: "Neither, I'm applying as a first time host.",
    platform: undefined,
  },
];
export const PlatformSelector = ({
  setPlatform,
  platform,
  options = defaultPlatformOptions,
}: {
  setPlatform: (platform?: HostingPlatform) => void;
  platform?: HostingPlatform;
  options?: Option[];
}) => {
  const applyContext = useApplyContext();
  return (
    <div className={styles.options}>
      {options.map((option) => {
        return (
          <Option
            data-cy={`platform-option-${option.title}`}
            onClick={() => {
              if (option.platform) {
                window?.analytics?.track("HOSTING_PLATFORM_SELECTED", {
                  platform: option.platform,
                });
                setPlatform(option.platform);
              } else {
                window?.analytics?.track("HOSTING_PLATFORM_SELECTED", {
                  platform: "NONE",
                });
                applyContext.setProvider({
                  ...applyContext,
                  isEligibleForHosting: false,
                });
                setPlatform(undefined);
                applyContext.navigation.addStep(StepEnum.INELIGIBLE);
              }
            }}
            selected={option.platform ? option.platform === platform : false}
            key={option.title}
            title={
              option.logo ? (
                <span>
                  {option.title}
                  {option.logo}
                </span>
              ) : (
                option.title
              )
            }
          />
        );
      })}
    </div>
  );
};

interface Props {
  onSubmit: (args: HostingEligibilitySubmitData) => void;
}
interface Option {
  title: string;
  logo?: React.ReactNode;
  platform?: HostingPlatform;
}

const HostingEligiblity = ({ onSubmit }: Props) => {
  const [platform, setPlatform] = useState<HostingPlatform | undefined>(
    undefined,
  );
  const applyContext = useApplyContext();

  const onClickReturnToPersonal = () => {
    window?.analytics?.track("RETURN_TO_PERSONAL_CLICKED");
    applyContext.setProvider({
      ...applyContext,
      loanClass: undefined,
      use: undefined,
    });
    applyContext.setNavigationHistory([StepEnum.LOAN_CLASS]);
  };

  return (
    <div className={styles.hostingEligibility}>
      <h1>Vehicle hosting eligibility questionnaire</h1>
      <p>Answer the questions below to see if you qualify</p>
      <h2>Which platform are you currently hosting with?</h2>
      <PlatformSelector
        setPlatform={setPlatform}
        platform={platform}
        options={defaultPlatformOptions}
      />
      {platform ? (
        <>
          <HostingEligibilityForm onSubmit={onSubmit} platform={platform} />
          <Button
            variant={ButtonVariant.PSEUDO_LINK}
            label="Return to personal loan application"
            className={styles.personalButton}
            onClick={onClickReturnToPersonal}
            data-cy="return-to-personal"
          />
        </>
      ) : (
        <Button
          variant={ButtonVariant.PSEUDO_LINK}
          label="Return to personal loan application"
          className={styles.personalButton}
          onClick={onClickReturnToPersonal}
          data-cy="return-to-personal"
        />
      )}
    </div>
  );
};

export default HostingEligiblity;
