import LOSClient from "#api.los/client";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  ChargerInstallDecisionInputDto,
  HTTPPostChargerInstallDecisionRes,
  LOSAPIResponse,
} from "./client.types";

/**
 * Posts charger election to an application, elected or declined
 * @param applicationId application to specify for charger
 * @param elected boolean, true = elected, false = declined
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const postChargerInstallDecision: (
  applicationId: string,
  payload: ChargerInstallDecisionInputDto,
) => LOSAPIResponse<HTTPPostChargerInstallDecisionRes> = async (
  applicationId: string,
  payload: ChargerInstallDecisionInputDto,
) => {
  LOSClient.tracking.post({
    event: `${payload.elected ? "SELECTED" : "DECLINED"}_CHARGER_INSTALL_ADDON`,
    details: { applicationId },
  });
  const response = await makeRequest<HTTPPostChargerInstallDecisionRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).chargerInstall.postChargerInstall,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default postChargerInstallDecision;
