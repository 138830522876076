import Radio from "#components/base/Radio/Radio";
import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";

import { Option } from "../ApplyPage";
import styles from "../ApplyPage.module.scss";
import {
  HostingEligibilityForm,
  HostingEligibilitySubmitData,
} from "./HostingEligibility";

interface Props {
  onSubmit: (args: HostingEligibilitySubmitData) => void;
}

const BusinessEligibility = ({ onSubmit }: Props) => {
  const applyContext = useApplyContext();

  const toggleMajorityOwner = (newVal: boolean) => {
    applyContext.setProvider({
      ...applyContext,
      hostingData: {
        ...applyContext.hostingData,
        majorityOwner:
          typeof applyContext?.hostingData?.majorityOwner === "boolean"
            ? !applyContext.hostingData.majorityOwner
            : newVal,
      },
    });
  };

  return (
    <div className={styles.businessEligibility}>
      <h1>Business eligibility questionnaire</h1>
      <p>Answer the questions below to see if you qualify</p>
      <h2>
        Are you a majority owner of an LLC business used for vehicle hosting
        with Turo or Getaround?
      </h2>

      <div className={styles.selections}>
        <Option
          data-cy="majority-owner-yes"
          selected={applyContext?.hostingData?.majorityOwner ?? false}
          onClick={() => toggleMajorityOwner(true)}
          title={
            <span>
              <Radio
                checked={applyContext?.hostingData?.majorityOwner ?? false}
              />{" "}
              <div className={styles.checkContent}>Yes</div>
            </span>
          }
        />
        <Option
          data-cy="majority-owner-no"
          selected={applyContext?.hostingData?.majorityOwner === false}
          onClick={() => onSubmit({ majorityOwner: false })}
          title={
            <span>
              <Radio
                checked={applyContext?.hostingData?.majorityOwner === false}
              />{" "}
              <div className={styles.checkContent}>No</div>
            </span>
          }
        />
      </div>
      {applyContext?.hostingData?.majorityOwner === true && (
        <HostingEligibilityForm
          onSubmit={(args) =>
            onSubmit({
              ...args,
              majorityOwner: true,
            })
          }
        />
      )}
    </div>
  );
};

export default BusinessEligibility;
