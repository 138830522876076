/** ******************************************************************************
Shared HTTP Structures
 * You know what these are.
 ******************************************************************************* */

// Common http types

export interface HTTPResponse<ResType> {
  error: boolean;
  statusText: string;
  status?: number;
  ok?: boolean;
  body?: ResType;
  redirected?: boolean;
  type?: string;
  url?: string;
}

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface HTTPOptions {
  // Temporarily disabled. I will create a type that can be passed in based on expected Payload = Jordon
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  headers?: Record<string, string>[];
}

export interface HTTPTokenRes {
  data: {
    token: string;
  };
}
