import { createStitches } from "@stitches/react";

import media, { screenSizes } from "./breakpoints";
import defaultTheme from "./default";
import * as utils from "./util";

export type Colors = keyof typeof defaultTheme.colors;
type FontSizes = keyof typeof defaultTheme.fontSizes;
export type Spaces = keyof typeof defaultTheme.space;
export type TextStyles = Exclude<FontSizes, number>;

export const { config, css, getCssText, globalCss, theme } = createStitches({
  media,
  theme: defaultTheme,
  utils,
});

export const globalThemeStyles = globalCss({
  body: { color: "$textPrimary", fontSize: "$body1" },
});

export const breakpoints = screenSizes;
