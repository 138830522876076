import { css } from "#themes";

export const root = css({
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  position: "relative",
  size: 24,
});

export const indicator = css({
  alignItems: "center",
  borderRadius: "50%",
  color: "$backgroundInverse",
  boxShadow: "inset 0 0 0 2px currentColor",
  display: "inline-flex",
  justifyContent: "center",
  size: 18,

  "&:after": {
    background: "currentColor",
    borderRadius: "50%",
    content: '""',
    display: "block",
    size: 10,
    transform: "scale(0)",
    transformOrigin: "center",
    transition: "$easeAll",
  },

  variants: {
    disabled: {
      true: {
        color: "$uiSubtle",
      },
    },
  },
});

export const indicatorSelected = css({
  color: "#9C44FB",
});

export const input = css({
  appearance: "none",
  color: "transparent",
  cursor: "pointer",
  zIndex: 2,

  "&:checked + span:after": {
    transform: "none",
  },
});
