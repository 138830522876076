/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import isNil from "lodash/isNil";
import noop from "lodash/noop";
import { useRouter } from "next/router";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { StepEnum } from "#components/partial/ApplyPage/ApplyPage";
import {
  HostingPlatform,
  LoanClassV2,
  LoanType,
  VehicleUsage,
} from "#components/partial/ApplyPage/ApplyPage.types";
import { HostingEligibilitySubmitData } from "#components/partial/ApplyPage/steps/HostingEligibility";
import { useApplicationContext } from "#components/provider/ApplicationProvider/ApplicationProvider";
import { useBigSessionState } from "#hooks/useBigState";

import { useConsumeQueries, useConsumeQuery } from "../Router.utils";
import { ApplyConfig } from "./Apply.types";

export { ApplyConfig } from "./Apply.types";

const DEFAULT_NAVIGATION_HISTORY = [StepEnum.LOAN_CLASS];

type Maybe<T> = T | undefined;

const definedOnly = (obj: Record<string, Maybe<any>>) => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) =>
    newObj[key] === undefined ? delete newObj[key] : {},
  );

  return newObj;
};

export const getCanCreateApplication = (applyContext: ApplyContext) => {
  return !(
    // Require loan type
    (
      !applyContext.loanType ||
      // Require loan VehicleUsage
      !applyContext.use ||
      // Require loan class
      !applyContext.loanClass ||
      // Require eligibility for hosting if class is commercial
      !(applyContext.loanClass === LoanClassV2.CommercialHybrid
        ? applyContext.isEligibleForHosting
        : true)
    )
  );
};

/**
 * WHEN YOU USE THE APPLY PROVIDER MAKE SURE IT'S WRAPPED BY THE ACTIVE APPLICATION PROVIDER
 */

export interface ApplyContext {
  loanType?: LoanType;
  loanClass?: LoanClassV2;
  use?: VehicleUsage;
  loading: boolean;
  setProvider: (provider: Partial<ApplyContext>) => void;
  onGoBack?: () => void;
  hostingData?: HostingEligibilitySubmitData;
  isEligibleForHosting?: boolean;
  reset: () => void;
}

interface ProviderDataOnly extends Omit<ApplyContext, "setProvider"> {}
interface ApplyContextNavigation {
  setNavigationHistory: (step: StepEnum[]) => void;
  navigation: Navigation;
}

export interface Navigation {
  history: StepEnum[];
  addStep: (step: StepEnum) => void;
}
interface ProviderDataOnly extends Omit<ApplyContext, "setProvider"> {}

export const getApplyConfigParameters = (
  applyConfig: ApplyConfig,
): Partial<ApplyContext> => {
  switch (applyConfig) {
    case ApplyConfig.STANDARD_NEW_LOAN:
      return {
        loanClass: LoanClassV2.Individual,
        loanType: LoanType.Purchase,
        use: VehicleUsage.Personal,
      };
    case ApplyConfig.STANDARD_REFINANCE:
      return {
        loanClass: LoanClassV2.Individual,
        loanType: LoanType.Refinance,
        use: VehicleUsage.Personal,
      };
    case ApplyConfig.RIDESHARE_NEW_LOAN:
      return {
        loanClass: LoanClassV2.Individual,
        loanType: LoanType.Purchase,
        use: VehicleUsage.Ridesharing,
      };
    default:
      const _exhaustiveCheck: never = applyConfig;
      return _exhaustiveCheck ? {} : {};
  }
};

export const useApplyConfigurationFromQuery = () => {
  // EV: we want to eventually typeguard this for the case where config isnt ApplyConfig
  const { setProvider } = useApplyContext();
  useConsumeQuery(
    "applyConfig",
    (config) => {
      const configParams = getApplyConfigParameters(config as ApplyConfig);
      setProvider(configParams);
    },
    {
      preserve: true,
    },
  );
};

export const useCustomApplyConfigurationFromQueryParams = () => {
  // If Query contains custom applyConfig, overwrite ApplyContext with custom config
  // This is particularly useful for redirect URLs on business loans
  const { setProvider } = useApplyContext();
  useConsumeQueries(
    [
      "avgRevenue",
      "majorityOwner",
      "months",
      "numEVs",
      "numICEs",
      "platform",
      "state",
      "years",
      "isEligibleForHosting",
      "loanClass",
      "loanType",
      "use",
    ],
    (queryParams) => {
      setProvider({
        hostingData: {
          ...(!isNil(queryParams.avgRevenue) && {
            avgRevenue: queryParams.avgRevenue,
          }),
          ...(!isNil(queryParams.majorityOwner) && {
            majorityOwner: Boolean(queryParams.majorityOwner),
          }),
          ...(!isNil(queryParams.months) && {
            months: Number(queryParams.months),
          }),
          ...(!isNil(queryParams.numEVs) && {
            numEVs: Number(queryParams.numEVs),
          }),
          ...(!isNil(queryParams.numICEs) && {
            numICEs: Number(queryParams.numICEs),
          }),
          ...(!isNil(queryParams.platform) && {
            platform: queryParams.platform as HostingPlatform,
          }),
          ...(!isNil(queryParams.state) && { state: queryParams.state }),
          ...(!isNil(queryParams.years) && {
            years: Number(queryParams.years),
          }),
        },
        ...(!isNil(queryParams.isEligibleForHosting) && {
          isEligibleForHosting: Boolean(queryParams.isEligibleForHosting),
        }),
        ...(!isNil(queryParams.loanClass) && {
          loanClass: queryParams.loanClass as LoanClassV2,
        }),
        ...(!isNil(queryParams.loanType) && {
          loanType: queryParams.loanType as LoanType,
        }),
        ...(!isNil(queryParams.use) && {
          use: queryParams.use as VehicleUsage,
        }),
      });
    },
  );
};

const ApplyContext = createContext<ApplyContext & ApplyContextNavigation>({
  loanType: undefined,
  loanClass: undefined,
  use: undefined,
  loading: false,
  setProvider: noop,
  navigation: {
    history: [StepEnum.LOAN_CLASS],
    addStep: noop,
  },
  setNavigationHistory: noop,
  reset: noop,
});

const getInitialStep = ({
  loanClass,
  loanType,
  use,
  hostingData,
}: {
  loanClass?: LoanClassV2;
  loanType?: LoanType;
  use?: VehicleUsage;
  hostingData?: HostingEligibilitySubmitData;
}): StepEnum => {
  switch (true) {
    case !loanClass:
      return StepEnum.LOAN_CLASS;
    case !loanType && !hostingData?.avgRevenue:
      return StepEnum.BUSINESS_ELIGIBILITY;
    case !loanType:
      return StepEnum.LOAN_TYPE;
    case !use:
      return StepEnum.USE;
    case use === VehicleUsage.Carsharing:
      return StepEnum.HOSTING_ELIGIBILITY;
    default:
      return StepEnum.LOAN_CLASS;
  }
};

const useApplyDeeplinking = (
  provider: Partial<ProviderDataOnly>,
  setProvider: ApplyContext["setProvider"],
  setNavigationHistory: ApplyContextNavigation["setNavigationHistory"],
) => {
  const { loading: applicationLoading } = useApplicationContext();

  const {
    loanType: routerLoanType,
    loanClass: routerLoanClass,
    use: routerUse,
    platform: routerPlatform,
  } = useConsumeQueries(["loanType", "loanClass", "use", "platform"] as const);

  // Application data - takes precedent if it's set on the app in the DB
  // Actually - I don't think we want to get any application data into apply provider
  // It was causing bugs with second applications after a user cancels their first.
  // Query parameters - for when users are linked into the /apply page
  const loanClass = routerLoanClass as Maybe<LoanClassV2>;
  const loanType = routerLoanType as Maybe<LoanType>;

  // Possible query params to pass in:
  // loanType: LoanType;
  // loanClass: LoanClass;
  // use: VehicleUsage;
  // platform: HostingPlatform;
  useEffect(() => {
    if (applicationLoading.all) {
      return;
    }

    const use =
      loanClass === LoanClassV2.CommercialHybrid
        ? VehicleUsage.Carsharing
        : (routerUse as Maybe<VehicleUsage>);

    setProvider(
      definedOnly({
        ...provider,
        loanClass: loanClass ?? undefined,
        loanType: loanType ?? undefined,
        use: use ?? undefined,
        ...(routerPlatform && {
          hostingData: {
            ...provider.hostingData,
            platform: routerPlatform as HostingPlatform,
          },
        }),
      }),
    );

    const initialStep = getInitialStep({
      loanClass,
      loanType,
      use,
      hostingData: provider.hostingData,
    });

    if (initialStep !== StepEnum.LOAN_CLASS) {
      setNavigationHistory([initialStep, StepEnum.LOAN_CLASS]);
    }
  }, [loanClass, loanType, routerUse, routerPlatform, applicationLoading]);
};

export const Apply = ({ children }: { children: React.ReactNode }) => {
  const { loading: applicationLoading } = useApplicationContext();
  const [navigationHistory, setNavigationHistory] = useState<StepEnum[]>([
    StepEnum.LOAN_CLASS,
  ]);

  const addStepToHistory = (step: StepEnum) => {
    const [mostRecentStep] = navigationHistory;
    if (mostRecentStep === step) {
      return;
    }

    setNavigationHistory([step, ...navigationHistory]);
  };

  const [provider, setProvider] = useBigSessionState<Partial<ProviderDataOnly>>(
    "applyContext",
    {
      loanType: undefined,
      loanClass: undefined,
      use: undefined,
      loading: false,
      reset: noop,
    },
  );

  useApplyDeeplinking(provider, setProvider, setNavigationHistory);

  const reset = () => {
    setProvider({
      loanType: undefined,
      loanClass: undefined,
      use: undefined,
      loading: false,
      hostingData: undefined,
      isEligibleForHosting: undefined,
      reset,
    });
    setNavigationHistory(DEFAULT_NAVIGATION_HISTORY);
  };

  useConsumeQuery("reset", (qv) => {
    if (qv === "true") {
      reset();
    }
  });

  const router = useRouter();
  const onGoBack = () => {
    const [mostRecentStep, ...otherSteps] = navigationHistory;
    setNavigationHistory(otherSteps);
    window?.analytics?.track("APPLY_GO_BACK", {
      step: mostRecentStep,
    });

    switch (mostRecentStep) {
      case StepEnum.USE:
        setProvider({ ...provider, use: undefined });
        break;
      case StepEnum.LOAN_TYPE:
        setProvider({
          ...provider,
          loanType: undefined,
        });
        break;
      case StepEnum.LOAN_CLASS:
        setProvider({
          ...provider,
          loanClass: undefined,
          hostingData: undefined,
          isEligibleForHosting: undefined,
        });
        router.push("/");
        return;
      case StepEnum.HOSTING_ELIGIBILITY:
        setProvider({
          ...provider,
          hostingData: undefined,
          isEligibleForHosting: undefined,
        });
        break;
      case StepEnum.BUSINESS_ELIGIBILITY:
        setProvider({
          ...provider,
          hostingData: undefined,
          isEligibleForHosting: undefined,
        });
        break;
      case StepEnum.PLATFORM:
        setProvider({
          ...provider,
          hostingData: undefined,
          isEligibleForHosting: undefined,
        });
        break;
      default:
        break;
    }
  };

  const [step] = navigationHistory;

  const memoizedProviderValue = useMemo(
    () => ({
      ...provider,
      setProvider,
      loading: applicationLoading.all,
      onGoBack,
      setNavigationHistory,
      navigation: {
        history: navigationHistory,
        addStep: addStepToHistory,
      },
      reset,
    }),
    [
      provider,
      setProvider,
      applicationLoading.all,
      onGoBack,
      navigationHistory,
      setNavigationHistory,
      addStepToHistory,
      step,
      reset,
    ],
  );

  return (
    <ApplyContext.Provider value={memoizedProviderValue}>
      {children}
    </ApplyContext.Provider>
  );
};

export const useApplyContext = () => useContext(ApplyContext);
