import { HTMLProps } from "react";
import { Control, UseFormRegisterReturn } from "react-hook-form";

export enum CardVariant {
  LIGHT_PURPLE = "LIGHT_PURPLE",
  LIGHT_BLUE = "LIGHT_BLUE",
  TRANSLUCENT = "TRANSLUCENT",
  LIGHT_BROWN = "LIGHT_BROWN",
  LIGHT_GREEN = "LIGHT_GREEN",
  LIGHT_GREY = "LIGHT_GREY",
  LIGHT_RED = "LIGHT_RED",
  LIGHT_WHITE = "LIGHT_WHITE",
  LIGHT_PINK = "LIGHT_PINK",
}

export type ControlledCardProps = {
  active?: boolean;
  checkbox?: boolean;
  disabled?: boolean;
  variant?: CardVariant;
  inputProps?: UseFormRegisterReturn;
  control: Control;
  error?: boolean;
};

export type CardProps = {
  isActive?: boolean;
  color?: string;
  checkbox?: boolean;
  disabled?: boolean;
  variant?: CardVariant;
  inputProps?: UseFormRegisterReturn;
  containerProps?: HTMLProps<HTMLDivElement>;
  centerAligned?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
};
