import noop from "lodash/noop";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { LoanType } from "#components/partial/ApplyPage/ApplyPage.types";
import { REFERRAL_QUERY_PARAM } from "#constants";
import ReferralService from "#services/ReferralService";
import {
  getFlowSegments,
  getPageNavigationInfo,
  LocationType,
} from "#util/pageNavigationHelpers";
import { Page } from "#util/pageNavigationHelpers.page";

import { useConsumeQuery } from "./Router.utils";

const replaceContext = createContext<(url: string) => void>((url: string) =>
  noop(url),
);
const pushContext = createContext<
  (url: string, as?: URL, options?: unknown) => void
>((url: string, as: unknown, options: unknown) => noop(url, as, options));
const navigatingToContext = createContext<string | undefined>(undefined);
const setNavigatingToContext = createContext<(arg: string | undefined) => void>(
  (isNavigating: string | undefined) => noop(isNavigating),
);

/**
 * Generates a url preserving its query string contents
 * @param query An object containing the expanded contents of a query string
 * attempting to be merged into the passed URL
 * @param url A url that may contain a querystring.
 * @returns updated url
 */
const getPreservedQueryUrl = (query: URLSearchParams, url: string) => {
  const basePath = (() => {
    if (typeof window !== "undefined") {
      return window.location.origin;
    }
    /* MPR, 2022/11/15: This will never happen, next js is weird sometimes */
    return "https://tenet.com";
  })();
  const baseUrl = new URL(url, basePath);
  Array.from(query.keys()).forEach((k) => {
    /* MPR, 2022/11/14: While multiple (array) keys are acceptable
     * in the QS standard, we as an organization do not have any
     * need for them. So we always overwrite with whichever is last
     */
    baseUrl.searchParams.delete(k);
    const items = query.getAll(k);
    const item = items.pop();
    baseUrl.searchParams.set(k, item as string);
  });

  return baseUrl.toString();
};

/**
 * A provider for extending nextJS router functionality
 * We initially created this to be able to change how the
 * replace and push navigation works, but it can be used
 * to adjust any property exposed by useRouter
 * @param props
 * @returns a provider wrapping the default nextjs router
 */
export const RouterProvider = ({ children }: { children: React.ReactNode }) => {
  const { push, replace, pathname, isReady } = useRouter();
  const [navigatingTo, setNavigatingTo] = useState<string | undefined>(
    undefined,
  );

  const querySafePush = useCallback<typeof push>((url, ...args) => {
    // Note: we cannot use the nextjs "query" in what they call
    // "prerender", for stupid reasons.
    let query = {} as URLSearchParams;
    if (typeof window !== "undefined") {
      query = new URLSearchParams(window.location.search);
    }

    return push(getPreservedQueryUrl(query, url.toString()), ...args);
  }, []);

  const querySafeReplace = useCallback<typeof replace>((url, ...args) => {
    // Note: we cannot use the nextjs "query" in what they call
    // "prerender", for stupid reasons.
    let query = {} as URLSearchParams;
    if (typeof window !== "undefined") {
      query = new URLSearchParams(window.location.search);
    }

    return replace(getPreservedQueryUrl(query, url.toString()), ...args);
  }, []);

  useEffect(() => {
    if (
      navigatingTo != null &&
      isReady &&
      navigatingTo === pathname.split("/").pop()
    ) {
      // was navigating, navigation complete
      setNavigatingTo(undefined);
    }
  }, [navigatingTo, pathname, isReady]);

  useConsumeQuery(REFERRAL_QUERY_PARAM, ReferralService.storeReferralCode);

  return (
    <navigatingToContext.Provider value={navigatingTo}>
      <setNavigatingToContext.Provider value={setNavigatingTo}>
        <replaceContext.Provider value={querySafeReplace as never}>
          <pushContext.Provider value={querySafePush as never}>
            {children}
          </pushContext.Provider>
        </replaceContext.Provider>
      </setNavigatingToContext.Provider>
    </navigatingToContext.Provider>
  );
};

export const useRouterContext = () => {
  const router = useRouter();
  const push = useContext(pushContext) as typeof router.push;
  const replace = useContext(replaceContext) as typeof router.replace;
  /* MPR, 2022/12/15: choosing not to expose this for the time being because
   * I don't really want it to be called outside of controlled contexts. There
   * may come a time where we need to set it manually, but lets avoid that if
   * we can help it */
  const setIsNavigating = useContext(setNavigatingToContext);
  const isNavigatingTo = useContext(navigatingToContext);
  /**
   * Clears the "nav" parameter that forces certain form flow pages
   * to display.
   */
  const clearPageIndex = () => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      if (params.has("nav")) {
        params.delete("nav");
        params.set("navUsed", "1");
        const newQuery = params.toString().length
          ? `?${params.toString()}`
          : "";
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newQuery}${window.location.hash}`;
        router.push(newurl);
      }
    }
  };
  /**
   * clears the "navUsed" parameter that prevents forcing form flow pages
   * from being forced to load.
   */
  const clearNavUsed = () => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      if (params.has("navUsed")) {
        params.delete("navUsed");
        const newQuery = params.toString().length
          ? `?${params.toString()}`
          : "";
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newQuery}${window.location.hash}`;
        router.push(newurl);
      }
    }
  };
  /**
   * Safely goes to a particular page, taking parter (Turo, etc) and flow (Refi, etc)
   * into account.
   */
  const navigateTo = async (
    page: Page,
    options?: {
      isRedirect?: boolean;
      product?: string;
      query?: Record<string, string | boolean | string[] | number>;
    },
  ) => {
    // MPR, 2023/09/01: This looks like nonsense to me, pretty sure this does nothing
    const rawNextQuery = {
      ...router.query,
      ...query,
      ...(options?.query as Record<string, string | string[] | undefined>),
    };
    const nextQuery = Object.keys(rawNextQuery).reduce(
      (a: Record<string, string>, k: string) => {
        if (rawNextQuery && rawNextQuery[k] != null && rawNextQuery[k] !== "") {
          return {
            ...a,
            [k]: rawNextQuery[k] as string,
          };
        }
        return a;
      },
      {},
    );
    if (query) {
      router.query = rawNextQuery;
    }

    const { isRedirect, product: overrideProduct } = options || {};
    const path = (() => {
      if (typeof window !== "undefined") {
        return window.location.pathname;
      }
      return router.asPath.split("?")[0] || "";
    })();
    const { stub, type, flowSpecific } = getPageNavigationInfo(page);
    const { partner, product: defaultProduct } = getFlowSegments(path);
    const isDashboard = path.indexOf("/dashboard") === 0;
    const isApply = path.indexOf("/apply") === 0;
    const product = overrideProduct || defaultProduct;
    /* MT, Check if destination is flowSpecific, if so the product must match the loanType */
    if (
      ((flowSpecific === LoanType.Refinance && product !== "refinance") ||
        (flowSpecific === LoanType.Purchase && product !== "new-loan")) &&
      !isDashboard &&
      !isApply
    ) {
      // eslint-disable-next-line no-console
      console.error(
        "Invoked invalid navigation to a flow-specific page. Unable to proceed with navigation call.",
      );
      return;
    }
    const nextQueryString =
      Object.keys(nextQuery).length > 0
        ? `?${new URLSearchParams(nextQuery).toString()}`
        : "";
    const navigate = isRedirect ? replace : push;
    /* MPR, 2023/2/12: "landing" page routes are used as-is, using only their provided stub. These are effectively normal links. */
    if (type === LocationType.landing) {
      setIsNavigating(stub);
      if (stub === "") {
        /* special case for root */
        await navigate("/");
      } else {
        await navigate(`/${stub}/${nextQueryString}`);
      }
    } else if (type === LocationType.portal) {
      /* MPR, 2023/2/12: "portal" page routes prepend 'dashboard', but do not otherwise modify the provided stub */
      setIsNavigating(stub);
      await navigate(`/dashboard/${stub}/${nextQueryString}`);
      // RMO: Shim for bad logic in getFlowSegments - need to rework
    } else if (partner && partner !== "dashboard") {
      /* MPR, 2023/2/12: "origination" page routes (the default) attempt to preserve the partner and product flow, e.g. /turo/new-loan/{stub} */
      setIsNavigating(stub);
      await navigate(`/${partner}/${product}/${stub}/${nextQueryString}`);
    } else {
      setIsNavigating(stub);
      await navigate(`/${product}/${stub}/${nextQueryString}`);
    }
  };

  /* MPR, 2023/3/29: Well, sometimes next.js just doesnt want to provide its path dynamic ids. Fine then. */
  const backupAbsoluteApplicationId = (() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.includes("/application/")) {
        /* MPR, 2023/3/29: If you're gunna be ugly, be ugly */
        const pathChunks = window.location.pathname.toString().split("/");
        const maybeAnId = pathChunks.pop();
        if (maybeAnId?.length === 36) {
          return maybeAnId;
        }
        const maybeDashboardId = pathChunks.pop();
        if (maybeDashboardId?.length === 36) {
          return maybeDashboardId;
        }
      }
    }
    return undefined;
  })();
  const backupAbsoluteLoanId = (() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.includes("/loan/")) {
        /* MPR, 2023/3/29: If you're gunna be ugly, be ugly */
        const pathChunks = window.location.pathname.toString().split("/");
        const maybeAnId = pathChunks.pop();
        if (maybeAnId?.length === 36) {
          return maybeAnId;
        }
        const maybeDashboardId = pathChunks.pop();
        if (maybeDashboardId?.length === 36) {
          return maybeDashboardId;
        }
      }
    }
    return undefined;
  })();

  /* MPR, 2023/4/5: Shimming query to guarentee that we have these IDs. We may need to extend this
   * in the future if there are other dynamic routes that we need to support. */
  const query = {
    loanId: backupAbsoluteLoanId,
    appId: backupAbsoluteApplicationId,
    ...router.query,
  } as typeof router.query;

  return {
    ...router,
    query,
    isNavigating: isNavigatingTo !== undefined,
    get path() {
      if (typeof window !== "undefined") {
        return window.location.pathname;
      }
      return router.asPath.split("?")[0] || "";
    },
    clearPageIndex,
    clearNavUsed,
    navigateTo,
    replace,
    push,
  };
};

export type NavigateToType = ReturnType<typeof useRouterContext>["navigateTo"];
