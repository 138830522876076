import { AFFieldValues } from "#components/base/Address/Address.types";

import { ASFieldValues } from "../ApplicationSummary/ApplicationSummary.types";
import { PLLFieldValues } from "../CurrentLoansList/CurrentLoansList.types";
import { EIFFieldValues } from "../EmploymentInfoForm/EmploymentInfoForm.types";
import { HIFFieldValues } from "../HousingInfoForm/HousingInfoForm.types";
import { GAPFieldValues } from "../LoanFormFlow/shared/GAPSelectionForm/GAPSelectionForm.types";
import { LOFieldValues } from "../LoanOffersForm/LoanOffersForm.types";
import { PLIFieldValues } from "../PreviousLoanInfoForm/PreviousLoanInfoForm.types";
import { SUFFieldValues } from "../SignUpForm/SignUpForm.types";
import { SSFFieldValues } from "../SocialSecurityForm/SocialSecurityForm.types";
import { VCFieldValues } from "../VehicleConfirmationForm/VehicleConfirmationForm.types";
import { VIFFieldValues } from "../VehicleInfoForm/VehicleInfoForm.types";

export type FormSubmitData =
  | VIFFieldValues
  | VCFieldValues
  | SUFFieldValues
  | AFFieldValues
  | HIFFieldValues
  | EIFFieldValues
  | ASFieldValues
  | SSFFieldValues
  | PLLFieldValues
  | PLIFieldValues
  | LOFieldValues
  | GAPFieldValues
  | null;

export type FormFlowErrorProps = {
  errorCode?: string;
  errorCondition?: boolean;
};

/**
 * In order for the progress bar on the Form Flow to be correct this Emum must be kept
 * in order of the flow progression - Jordon
 */
export enum RefiStep {
  RESUME_LOAN_FORM = "RESUME_LOAN_FORM",
  STATE_FORM = "STATE_FORM",
  SERVICE_UPDATE_FORM = "SERVICE_UPDATE_FORM",
  VEHICLE_INFO_FORM = "VEHICLE_INFO_FORM",
  VEHICLE_CONFIRMATION = "VEHICLE_CONFIRMATION",
  SIGNUP_FORM = "SIGNUP_FORM",
  PERSONAL_INFO_FORM = "PERSONAL_INFO_FORM",
  ADDRESS_FORM = "ADDRESS_FORM",
  EMAIL_WALL = "EMAIL_WALL",
  SOFT_CREDIT_CONSENT = "SOFT_CREDIT_CONSENT",
  SOCIAL_SECURITY_FORM = "SOCIAL_SECURITY_FORM",
  SOFT_CREDIT_ERROR = "SOFT_CREDIT_ERROR",
  PREVIOUS_LOAN_LIST = "PREVIOUS_LOAN_LIST",
  PREVIOUS_LOAN_INFO = "PREVIOUS_LOAN_INFO",
  EMPLOYMENT_INFO_FORM = "EMPLOYMENT_INFO_FORM",
  HOUSING_INFO_FORM = "HOUSING_INFO_FORM",
  APPLICATION_SUMMARY = "APPLICATION_SUMMARY",
  LOAN_OFFERS_FORM = "LOAN_OFFERS_FORM",
  LOAN_OFFERS_SUCCESS = "LOAN_OFFERS_SUCCESS",
  GAP_SELECTION_FORM = "GAP_SELECTION_FORM",
  HARD_CREDIT_FORM = "HARD_CREDIT_FORM",
  FINAL_REFI_OFFER = "FINAL_REFI_OFFER",
  LOAN_CONTRACT = "LOAN_CONTRACT",
  LOAN_APP_COMPLETE = "LOAN_APP_COMPLETE",
}
