import { css } from "#themes";

export const tooltip = css({
  background: "$backgroundInverse",
  borderRadius: 16,
  color: "$textInverse",
  fontSize: 12,
  lineHeight: 1.5,
  maxWidth: 320,
  padding: "$gutter",
  zIndex: 1,

  variants: {
    variant: {
      inverted: {
        color: "$textPrimary",
        backgroundColor: "$backgroundPrimary",
        border: "1px solid black",
      },
    },
  },
});

export const trigger = css({
  display: "inline-flex",
  minHeight: 24,
  minWidth: 24,
});
