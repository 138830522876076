import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { CreateApplicationDraftParams } from "#components/provider/ApplicationProvider/ApplicationTypes";
import { restPaths } from "#constants/restPaths";

import { HTTPcreateApplicationRes, LOSAPIResponse } from "./client.types";

/**
 * creates a draft loan application for users that are not authenticated
 * @param vin refinance vehicle vin (string)
 * @param state refinance vehicle state (string)
 * @param plate refinance vehicle license plate (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */

const createDraft: (
  vehicleInfo: CreateApplicationDraftParams,
) => LOSAPIResponse<HTTPcreateApplicationRes> = async (vehicleInfo) => {
  const query = vehicleInfo.vin
    ? `?vin=${vehicleInfo.vin}`
    : `?state=${vehicleInfo.state}&plate=${vehicleInfo.plate}`;
  const response = await makeRequest<HTTPcreateApplicationRes>(
    HTTPMethod.POST,
    `${restPaths.application().draft}${query}`,
    {},
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default createDraft;
