import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { PaymentMethod as PaymentMethodType } from "#api.lms/client.types";
import { PaymentDayOfMonth } from "#api.lms/lmsDto.types";
import LOSClient from "#api.los/client";
import { Application } from "#api.los/client.types";
// import { DropdownOption } from "#components/base/Dropdown/Dropdown.types";
import DropdownField from "#components/base/DropdownField/DropdownField";
import { ArrowLeft, AutopayClock } from "#components/base/Icon/Icon";
import {
  getTotalInterestWithAddons,
  getTotalLoanValueWithAddonsWithoutInterest,
} from "#components/partial/FinalLoanSummary/FinalLoanSummary.util";
import { useApplicationContext } from "#components/provider/ApplicationProvider/ApplicationProvider";
import { usePaymentMethodProvider } from "#components/provider/PaymentMethods";
import { tenetAlert } from "#util/alerts/alerts";
import {
  centsToDollarString,
  numberToPercentString,
} from "#util/number/number";
import Button from "#v2-components/molecules/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "#v2-components/molecules/Button/Button.types";

import LoadingState from "../LoadingState/LoadingState";
import AddPaymentsProvider from "../PaymentsManager/AddPaymentsProvider";
import s from "./PaymentPreferences.module.scss";

interface Props {
  onSubmit: (hasSelectedAutopay: boolean) => Promise<void>;
}

const InitialStep = ({
  nextStep,
  onDecline,
  application,
}: {
  nextStep: () => void;
  onDecline: () => void;
  application: Application;
}) => {
  const { selectedOffer } = application.offers;
  const lifetimeDiscount = centsToDollarString(
    selectedOffer?.autopayTila.lifetimeDiscountCents || 0,
  );

  return (
    <div className={s.container}>
      <div className={s.main}>
        <div className={s.details}>
          <AutopayClock size={80} />
          <h3>
            Set up autopay to keep your 0.25% APR discount<sup>*</sup>
          </h3>
          <div>
            <p>
              <span>
                Save {lifetimeDiscount} over the lifetime of your loan.
              </span>{" "}
              No payment will be taken at this time.
            </p>
            <p>
              A payment start date will only be scheduled if your loan is
              funded.
            </p>
          </div>
        </div>

        <div className={s.disclaimer}>
          * Autopay Discount is an optional ACH recurring payment rate
          reduction. Enroll in Autopay before your first payment is due to claim
          your discount. Enrolling in Autopay is not mandatory for loan
          approval. However, Tenet will remove the discount if you do not set up
          Autopay or cancel it later.
        </div>
      </div>
      <div className={s.buttonsContainer}>
        <Button
          variant={ButtonVariant.DARK_OUTLINE}
          onClick={onDecline}
          data-cy="autopay-decline"
        >
          Remove 0.25% discount
        </Button>
        <Button
          variant={ButtonVariant.DARK_FILLED}
          onClick={nextStep}
          data-cy="autopay-start"
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

// const AutopayDate = ({
//   setAutopayDate,
//   next,
// }: {
//   setAutopayDate: Dispatch<SetStateAction<PaymentDayOfMonth>>;
//   next: () => void;
// }) => {
//   const dateOptions = Object.keys(PaymentDayOfMonth).reduce(
//     (acc: DropdownOption[], curr: string) => {
//       if (Object.keys(PaymentDayOfMonthShortened).includes(curr)) {
//         const key = curr as PaymentDayOfMonth;

//         const currentOption = {
//           value: curr,
//           text: `${PaymentDayOfMonthShortened[key]} of the month`,
//         };

//         acc.push(currentOption);
//       }
//       return acc;
//     },
//     [] as DropdownOption[],
//   );

//   const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setAutopayDate(PaymentDayOfMonth[e.target.value] as PaymentDayOfMonth);
//   };

//   return (
//     <div className={s.autopayDateContainer}>
//       <div className={s.main}>
//         <div className={s.details}>
//           <h3>Autopay date</h3>
//           <p>
//             Payments will be automatically drafted on the date you choose. If
//             your draft date falls on a holiday, the payment will be deducted on
//             the next business day.
//           </p>
//         </div>
//         <div className={s.dropdownContainer}>
//           <DropdownField
//             className={s.inputField}
//             id="autopayment-date"
//             label={<p>Monthly draft date</p>}
//             placeholder="Select one"
//             aria-label="autopayment-date"
//             options={dateOptions}
//             name="Autopayment Date"
//             onChange={handleChange}
//           />
//         </div>
//       </div>

//       <div className={s.buttonsContainer}>
//         <Button variant={ButtonVariant.DARK_FILLED} onClick={next}>
//           Submit
//         </Button>
//       </div>
//     </div>
//   );
// };

const AutopayReview = ({
  paymentAmount,
  paymentMethod,
  next,
  isLoading,
}: // frequency,
{
  paymentAmount: number;
  paymentMethod: PaymentMethodType;
  next: () => Promise<void>;
  isLoading: boolean;
  // frequency?: PaymentDayOfMonth;
}) => {
  return !isLoading ? (
    <div className={s.reviewContainer}>
      <div className={s.main}>
        <h3>Review</h3>
        <p>
          <strong>No payment will be taken at this time.</strong> A payment
          start date will only be scheduled if your loan is funded.
        </p>

        <div className={s.box}>
          <div>
            <p>Payment type</p>
            <p>Monthly</p>
          </div>

          <div className={s.row}>
            <p>Monthly payment</p>
            <p>{centsToDollarString(paymentAmount)}</p>
          </div>

          <div className={s.row}>
            <p>Bank account</p>
            <p className={s.bankAccountDetails}>
              {paymentMethod?.accountName} (****
              {paymentMethod?.accountNumberLast4Digits})
            </p>
          </div>
        </div>

        <div className={s.box}>
          <div className={s.row}>
            <p>Frequency</p>
            <p className={s.pendingFunding}>Pending Funding</p>
            {/* <p>
              Monthly on the{" "}
              {PaymentDayOfMonthShortened[frequency || PaymentDayOfMonth.First]}
            </p> */}
          </div>

          <div className={s.row}>
            <p>Start date</p>
            <p className={s.pendingFunding}>Pending funding</p>
          </div>

          <p className={s.boxDisclaimer}>
            You can modify this later if your loan is funded
          </p>
        </div>
      </div>

      <div className={s.disclaimer}>
        <p>
          <strong>
            A payment start date will only be scheduled if your loan is funded.
          </strong>
          You authorize the recurring draft for the amount shown above and agree
          to the{" "}
          <a
            href="https://tenet-mvp-documents.s3.us-west-2.amazonaws.com/ACH+Debits+and+Credits+Terms+%26+Conditions.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Automatic Payment Withdrawal Terms & Conditions.
          </a>
        </p>
        <div className={s.submitBtn}>
          <Button
            variant={ButtonVariant.DARK_FILLED}
            onClick={next}
            data-cy="finish-button"
          >
            Finish
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <LoadingState />
  );
};

const onAutopaySubmit = async ({
  paymentMethodId,
  applicationId,
  onSubmit,
  dayOfMonth,
  setIsLoading,
}: {
  paymentMethodId?: string;
  applicationId: string;
  onSubmit: (hasSelectedAutopay: boolean) => Promise<void>;
  dayOfMonth: PaymentDayOfMonth;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  try {
    setIsLoading(true);
    if (!paymentMethodId) {
      await onSubmit(true);
      return;
    }
    const { response } = await LOSClient.application.payments.getAutopay(
      applicationId,
    );

    if (response?.body?.data?.id) {
      await LOSClient.application.payments.updateAutopay(
        applicationId,
        response?.body?.data?.id,
        dayOfMonth,
        paymentMethodId,
      );
    } else {
      await LOSClient.application.payments.createAutopay(
        applicationId,
        dayOfMonth,
        paymentMethodId,
      );
    }

    await onSubmit(true);
  } catch (e) {
    tenetAlert("There was an error setting up autopay", { type: "error" });
  }
  setIsLoading(false);
};

const onAutopayCancel = async ({
  applicationId,
  onSubmit,
  setIsLoading,
}: {
  applicationId: string;
  onSubmit: (hasSelectedAutopay: boolean) => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  try {
    setIsLoading(true);
    const { response } = await LOSClient.application.payments.getAutopay(
      applicationId,
    );

    if (response?.body?.data?.id) {
      await LOSClient.application.payments.cancelAutopay(applicationId);
    }

    await onSubmit(false);
  } catch (e) {
    tenetAlert("There was an error cancelling autopay", { type: "error" });
  }
  setIsLoading(false);
};

const PaymentMethod = ({
  setSelectedAccount,
  selectedAccount,
  next,
  onSubmit,
  isLoading,
  setIsLoading,
}: {
  setSelectedAccount: Dispatch<SetStateAction<PaymentMethodType | undefined>>;
  selectedAccount?: PaymentMethodType;
  next: () => void;
  onSubmit: (hasSelectedAutopay: boolean) => Promise<void>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { data: accountsData } = usePaymentMethodProvider();
  const accounts = accountsData?.data || [];

  useEffect(() => {
    if (!selectedAccount && accounts) {
      setSelectedAccount(accounts[0]);
    }
  }, [accounts]);

  const onSkipClick = async () => {
    try {
      setIsLoading(true);
      await onSubmit(true);
    } catch (e) {
      tenetAlert("There was an error setting up autopay", { type: "error" });
    }
    setIsLoading(false);
  };

  const handleChange = (id: string) => {
    const matchingAccount = accounts.find((account) => account.id === id);
    setSelectedAccount(matchingAccount);
  };
  const renderPaymentMethods = () => {
    if (accounts) {
      return (
        <>
          <p>Payment method</p>
          <DropdownField
            options={accounts.map((account) => {
              return {
                value: account.id,
                text: `${account.accountName} ${account.accountNumberLast4Digits}`,
              };
            })}
            name="account-dropdown"
            aria-label="thing"
            defaultValue={selectedAccount?.id}
            value={selectedAccount?.id}
            onChange={(e) => handleChange(e.target.value)}
            className={s.accountDropdown}
          />
        </>
      );
    }
    return false;
  };

  return !isLoading ? (
    <div className={s.paymentMethodContainer}>
      <div className={s.main}>
        <AddPaymentsProvider
          header="Set up ACH payment method"
          body=""
          renderComponent={({ addPayments }) => {
            return (
              <div className={s.details}>
                <div className={s.titleSection}>
                  <h3>Payment method</h3>
                  <p>
                    Link your bank account to set up loan payments and get the
                    0.25% APR discount
                  </p>
                </div>
                {accounts[0] ? (
                  <>
                    <div className={s.dropdownContainer}>
                      {renderPaymentMethods()}
                    </div>
                    <Button
                      forceSize={ButtonSize.SMALL}
                      variant={ButtonVariant.BLUE_FILLED}
                      onClick={addPayments.openModal}
                      data-cy="link-bank-button"
                    >
                      + Link bank account
                    </Button>
                  </>
                ) : (
                  <div className={s.accountWidget}>
                    <div>
                      <p>No payment methods saved. </p>
                      <p>Add a payment method to continue.</p>
                    </div>

                    <Button
                      forceSize={ButtonSize.SMALL}
                      variant={ButtonVariant.BLUE_FILLED}
                      onClick={addPayments.openModal}
                      data-cy="link-bank-button"
                    >
                      + Link bank account
                    </Button>
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
      <div className={s.buttonsContainer}>
        {!accounts.length && (
          <Button
            variant={ButtonVariant.PSEUDO_LINK}
            onClick={onSkipClick}
            data-cy="autopay-skip-setup"
          >
            Skip, do this later
          </Button>
        )}
        <Button
          variant={ButtonVariant.DARK_FILLED}
          onClick={next}
          disabled={!selectedAccount}
          data-cy="autopay-continue-setup"
        >
          Continue
        </Button>
      </div>
    </div>
  ) : (
    <LoadingState />
  );
};

const DeclineStep = ({
  onSubmit,
  application,
  nextStep,
  setIsLoading,
  isLoading,
}: {
  application: Application;
  nextStep: () => void;
  onSubmit: (hasSelectedAutopay: boolean) => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}) => {
  const autopay = application?.offers.selectedOffer?.autopayTila;
  const standard = application?.offers.selectedOffer?.tila;
  const autopayLifetimeCents =
    getTotalInterestWithAddons(autopay) +
    getTotalLoanValueWithAddonsWithoutInterest(autopay);
  const standardLifetimeCents =
    getTotalInterestWithAddons(standard) +
    getTotalLoanValueWithAddonsWithoutInterest(standard);

  return !isLoading ? (
    <div className={s.declineContainer}>
      <div className={s.main}>
        <h3>Confirm APR increase</h3>
        <div className={s.details}>
          <h4>
            <ExclamationTriangleIcon height={20} width={20} /> Autopay discount
            removed
          </h4>
          <p>
            By choosing to not use autopay, you will no longer receive the
            autopay discount of 0.25% previously included on your APR quote.
          </p>
          <hr />
          <div className={s.comparisonContainer}>
            <div className={s.autopayCard}>
              <p>
                <strong>With autopay discount</strong>
              </p>
              <p>
                Monthly payment{" "}
                {centsToDollarString(autopay?.monthlyPaymentCents || 0)}
              </p>
              <p>APR {numberToPercentString(autopay?.apr || 0)}</p>
              <p>
                Estimated total interest{" "}
                {centsToDollarString(autopay?.totalInterestCents || 0)}
              </p>
              <p>
                Total of payments {centsToDollarString(autopayLifetimeCents)}
              </p>
            </div>
            <div className={s.standardCard}>
              <p>
                <strong>New loan total</strong>
              </p>
              <p>
                Monthly payment{" "}
                {centsToDollarString(standard?.monthlyPaymentCents || 0)}
              </p>
              <p>APR {numberToPercentString(standard?.apr || 0)}</p>
              <p>
                Estimated total interest{" "}
                {centsToDollarString(standard?.totalInterestCents || 0)}
              </p>
              <p>
                Total of payments {centsToDollarString(standardLifetimeCents)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={s.buttonsContainer}>
        <Button
          variant={ButtonVariant.PSEUDO_LINK}
          onClick={nextStep}
          data-cy="back-to-autopay-button"
        >
          <ArrowLeft /> Back to autopay setup
        </Button>
        <Button
          variant={ButtonVariant.DARK_FILLED}
          onClick={() =>
            onAutopayCancel({
              onSubmit,
              applicationId: application.id,
              setIsLoading,
            })
          }
          data-cy="confirm-apr-increase"
        >
          Confirm APR increase
        </Button>
      </div>
    </div>
  ) : (
    <LoadingState />
  );
};

const PaymentPreferences = ({ onSubmit }: Props) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedAccount, setSelectedAccount] = useState<PaymentMethodType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [autopayDate, setAutopayDate] = useState<PaymentDayOfMonth>(
  //   PaymentDayOfMonth.First,
  // );
  const { state } = useApplicationContext();
  const { application } = state;

  const renderActiveStep = () => {
    switch (true) {
      case activeStep === -1:
        return application ? (
          <DeclineStep
            nextStep={() => {
              setActiveStep(0);
            }}
            onSubmit={onSubmit}
            application={application}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ) : (
          <LoadingState />
        );
      case activeStep === 0:
        return application ? (
          <InitialStep
            nextStep={() => {
              setActiveStep(1);
            }}
            onDecline={() => setActiveStep(-1)}
            application={application}
          />
        ) : (
          <LoadingState />
        );
      case activeStep === 1:
        return (
          <PaymentMethod
            setSelectedAccount={setSelectedAccount}
            selectedAccount={selectedAccount}
            next={() => setActiveStep(2)}
            onSubmit={onSubmit}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      // case activeStep === 2:
      // return (
      //   <AutopayDate
      //     setAutopayDate={setAutopayDate}
      //     next={() => setActiveStep(3)}
      //   />
      // );
      case activeStep === 2:
        return selectedAccount ? (
          <AutopayReview
            // frequency={autopayDate}
            paymentAmount={
              application?.offers.selectedOffer?.autopayTila
                .monthlyPaymentCents || 0
            }
            paymentMethod={selectedAccount}
            isLoading={isLoading}
            next={() =>
              onAutopaySubmit({
                paymentMethodId: selectedAccount?.id,
                applicationId: application?.id || "",
                // dayOfMonth: autopayDate,
                dayOfMonth: PaymentDayOfMonth.First,
                onSubmit,
                setIsLoading,
              })
            }
          />
        ) : (
          <LoadingState />
        );

      default:
        return application ? (
          <InitialStep
            nextStep={() => {
              setActiveStep(1);
            }}
            onDecline={() => setActiveStep(-1)}
            application={application}
          />
        ) : (
          <LoadingState />
        );
    }
  };

  const navData = [
    {
      step: "Payment",
      disabled: false,
    },
    // {
    //   step: "Date",
    //   disabled: !selectedAccount,
    // },
    {
      step: "Review",
      disabled: !selectedAccount,
      // disabled: !autopayDate && !selectedAccount,
    },
  ];

  return (
    <>
      {activeStep > 0 && (
        <div className={s.nav}>
          {navData.map((step, index) => (
            <button
              key={step.step}
              type="button"
              onClick={() => setActiveStep(index + 1)}
              disabled={step.disabled}
            >
              {step.step}
              <div
                className={activeStep === index + 1 ? s.active : undefined}
              />
            </button>
          ))}
        </div>
      )}
      {renderActiveStep()}
    </>
  );
};

export default PaymentPreferences;
