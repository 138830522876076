/* eslint-disable react/jsx-no-target-blank */

import React from "react";

import { ArrowRight, BusinessTag } from "#components/base/Icon/Icon";
import Button from "#v2-components/molecules/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "#v2-components/molecules/Button/Button.types";

import styles from "../ApplyPage.module.scss";
import { LoanClassV2 as LoanClassEnum } from "../ApplyPage.types";

interface Props {
  onSubmit: (loanClass: LoanClassEnum) => void;
}

export const LoanClassCard = ({
  imageSrc,
  title,
  subtitle,
  buttonLabel,
  buttonVariant = ButtonVariant.DARK_FILLED,
  businessTag = false,
  hasWaitlist = false,
  isBusiness = false,
  cy,
  onClick,
}: {
  imageSrc: string;
  title: string;
  subtitle?: string;
  buttonLabel: string;
  buttonVariant?: ButtonVariant;
  businessTag?: boolean;
  isBusiness?: boolean;
  onClick?: () => void;
  hasWaitlist?: boolean;
  cy: string;
}) => {
  return (
    <div
      className={styles.loanClassCard}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <div>
        <div className={styles.topContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.textContainer}>
              {businessTag && <BusinessTag className={styles.businessIcon} />}
              <h4>{title}</h4>
              {subtitle && <p>{subtitle}</p>}
            </div>
            {isBusiness && (
              <img
                src={imageSrc}
                className={styles.imageBusiness}
                style={{ marginTop: "15px" }}
                alt={title}
              />
            )}
            <Button
              onClick={onClick}
              variant={buttonVariant}
              forceSize={ButtonSize.SMALL}
              label={buttonLabel}
              className={styles.cardButton}
              data-cy={cy}
            />
          </div>
          {!isBusiness && (
            <img src={imageSrc} className={styles.image} alt={title} />
          )}
          ,
        </div>

        {hasWaitlist && (
          <>
            <div className={styles.contentDivider} />
            <div className={styles.bottomContainer}>
              <span className={styles.bodyText}>
                Join the waitlist to be notified when loans for carsharing
                businesses are available in your state.
              </span>
              <span className={styles.bodyText}>
                <a
                  href="https://tenetenergy.typeform.com/to/u3Qy7Hvz"
                  target="_blank"
                  rel="noopener"
                >
                  <text className={styles.text}> Join waitlist</text>
                </a>
                <a
                  href="https://tenetenergy.typeform.com/to/u3Qy7Hvz"
                  target="_blank"
                  rel="noopener"
                >
                  {" "}
                  <ArrowRight size={22} style={{ color: "#0C5A93" }} />
                </a>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const LoanClass = ({ onSubmit }: Props) => {
  return (
    <div className={styles.loanClass}>
      <h1 className={styles.title}>
        Select your application type to get started
      </h1>
      <LoanClassCard
        onClick={() => onSubmit(LoanClassEnum.Individual)}
        buttonLabel="Apply"
        imageSrc="/img/apply/apply-personal.png"
        title="Individual"
        subtitle="Purchase or refinance a vehicle. Carshare hosting is available for qualifying applicants."
        cy="class-individual"
      />
      <LoanClassCard
        onClick={() => onSubmit(LoanClassEnum.CommercialHybrid)}
        businessTag
        buttonLabel="Apply via your business"
        buttonVariant={ButtonVariant.DARK_OUTLINE}
        imageSrc="/img/apply/apply-business.png"
        title="Apply via your business"
        subtitle="Finance multiple cars for hosting with Turo or Getaround."
        cy="class-business"
        isBusiness
        hasWaitlist={false}
      />
    </div>
  );
};

export default LoanClass;
