import { useState } from "react";

import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";
import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import { Option } from "../ApplyPage";
import styles from "../ApplyPage.module.scss";
import { LoanType, VehicleUsage as UseType } from "../ApplyPage.types";

interface Props {
  onSubmit: (use: UseType) => void;
}

const supportedPurchaseOptions = [
  {
    type: UseType.Ridesharing,
    title: "Ridesharing",
  },
];

const defaultOptions = [
  {
    type: UseType.Personal,
    title: "Personal use",
  },

  {
    type: UseType.Carsharing,
    title: "Hosting with a car sharing platform",
  },
];

const useUsageController = (onSubmit: Props["onSubmit"]) => {
  const applyContext = useApplyContext();
  const [use, setUse] = useState<UseType | null>(
    applyContext.use || UseType.Personal,
  );

  // Options
  const supportedOptions = [
    ...defaultOptions,
    ...(applyContext.loanType === LoanType.Purchase
      ? supportedPurchaseOptions
      : []),
  ];

  const sortedOptions = supportedOptions.sort((a, b) =>
    a.title.length > b.title.length ? 1 : -1,
  );

  // options

  const submit = () => {
    if (use) {
      window?.analytics?.track("SUBMIT_LOAN_USE", {
        use,
      });
      onSubmit(use);
    }
  };

  const selectOption = (selectedUse: UseType) => {
    window?.analytics?.track("SELECT_LOAN_USE", {
      use: selectedUse,
    });
    setUse(selectedUse);
  };

  return {
    use,
    options: sortedOptions,
    selectOption,
    submit,
  };
};

const UsageOption = ({
  type,
  title,
  selected,
  selectOption,
}: {
  type: UseType;
  title: string;
  selected: boolean;
  selectOption: (type: UseType) => void;
}) => (
  <Option
    selected={selected}
    title={title}
    onClick={() => selectOption(type)}
    data-cy={`use-${type?.toLowerCase()}`}
  />
);

const Use = ({ onSubmit }: Props) => {
  const { use, options, selectOption, submit } = useUsageController(onSubmit);

  return (
    <div className={styles.loanType}>
      <h1 className={styles.title}>How will you use your vehicle?</h1>
      <div className={styles.loanTypeCards}>
        {options.map(({ type, title }) => (
          <UsageOption
            key={type}
            type={type}
            title={title}
            selected={use === type}
            selectOption={selectOption}
          />
        ))}
        <Button
          variant={ButtonVariant.DARK_FILLED}
          className={styles.continueButton}
          label="Continue"
          disabled={!use}
          data-cy="use-submit"
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default Use;
