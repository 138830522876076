import LOSClient from "#api.los/client";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  ChargerPricingInputDto,
  HTTPChargerPricingRes,
  LOSAPIResponse,
} from "./client.types";

/**
 * Posts charger election to an application, elected or declined
 * @param applicationId application to specify for charger
 * @param elected boolean, true = elected, false = declined
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const createChargerPricing: (
  applicationId: string,
  payload: ChargerPricingInputDto,
) => LOSAPIResponse<HTTPChargerPricingRes> = async (
  applicationId: string,
  payload: ChargerPricingInputDto,
) => {
  LOSClient.tracking.post({
    event: `GENERATED_CHARGER_PRICING_WITH_${payload.externalSku}`,
    details: { applicationId },
  });
  const response = await makeRequest<HTTPChargerPricingRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).charger.postChargerPricing,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default createChargerPricing;
