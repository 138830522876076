import type { Options, State } from "@popperjs/core";
import { useState } from "react";
import { usePopper } from "react-popper";

interface UsePopover {
  /**
   * `popoverState` contains useful information like which direction the popover
   * will render, references to the popover/anchor, and sizing and position
   * values of those references.
   */
  popoverState: State | null;
  /**
   * Styles for positioning the `popoverElement` within the viewport. You
   * should pass these, along with the `setPopoverElement` ref fn, to the
   * element you want to use as the popover:
   *
   * ```js
   * <div ref={setPopoverElement} style={popoverStyles} />
   * ```
   */
  popoverStyles: React.CSSProperties;
  /**
   * A function for capturing the ref of your popover trigger - this is what the
   * popover will be positioned relative to.
   */
  setAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  /**
   * A function for capturing the ref of the popover element itself.
   */
  setPopoverElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  /**
   * Force update the popover position. You may want to do this before or after
   * a transition runs.
   */
  updatePosition: () => void;
}

// usePopover is a wrapper for `usePopper`, which in turn is a react binding for
// the `popperjs` positioning engine. This hook returns an object containing a
// subset of the state and styles for a popper instance, as well as functions
// for setting the Anchor and Popover elements used by popper.
// For more info, see:
// - https://popper.js.org/react-popper/v2/hook/
// - https://popper.js.org/docs/v2/constructors/#createpopper
const usePopover = (popperOptions: Options): UsePopover => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(
    null,
  );
  const { state, styles, update } = usePopper(
    anchorElement,
    popoverElement,
    popperOptions,
  );

  const updatePosition = () => {
    update?.();
  };

  return {
    popoverState: state,
    popoverStyles: styles.popper,
    setAnchorElement,
    setPopoverElement,
    updatePosition,
  };
};

export default usePopover;
