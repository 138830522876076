import { useState } from "react";

import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";
import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import { Option } from "../ApplyPage";
import styles from "../ApplyPage.module.scss";
import { LoanType as LoanTypeType } from "../ApplyPage.types";

interface Props {
  onSubmit: (args: LoanTypeType) => void;
}

const LoanType = ({ onSubmit }: Props) => {
  const applyContext = useApplyContext();
  const [loanType, setLoanType] = useState<LoanTypeType | null>(
    applyContext.loanType || LoanTypeType.Purchase,
  );
  return (
    <div className={styles.loanType}>
      <h1 className={styles.title}>What type of auto loan do you need?</h1>
      <div className={styles.loanTypeCards}>
        <Option
          selected={loanType === LoanTypeType.Purchase}
          title="Vehicle purchase"
          onClick={() => {
            window?.analytics?.track("SELECT_LOAN_TYPE", {
              loanType: LoanTypeType.Purchase,
            });
            setLoanType(LoanTypeType.Purchase);
          }}
          data-cy="loan-type-purchase"
        />
        <Option
          selected={loanType === LoanTypeType.Refinance}
          title="Refinance a vehicle I already own"
          onClick={() => {
            window?.analytics?.track("SELECT_LOAN_TYPE", {
              loanType: LoanTypeType.Purchase,
            });
            setLoanType(LoanTypeType.Refinance);
          }}
          data-cy="loan-type-refi"
        />
        <Button
          variant={ButtonVariant.DARK_FILLED}
          className={styles.continueButton}
          label="Continue"
          disabled={!loanType}
          onClick={() => {
            if (loanType) {
              window?.analytics?.track("SUBMIT_LOAN_TYPE", {
                loanType,
              });
              onSubmit(loanType);
            }
          }}
          data-cy="loan-type-submit"
        />
      </div>
    </div>
  );
};

export default LoanType;
