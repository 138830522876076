export enum LoanType {
  Purchase = "Purchase",
  Refinance = "Refinance",
}

export enum LoanClassV2 {
  Commercial = "CommercialHybrid",
  CommercialHybrid = "CommercialHybrid",
  Individual = "Individual",
}

export enum HostingPlatform {
  Getaround = "Getaround",
  Turo = "Turo",
}

export enum VehicleUsage {
  Personal = "Personal",
  Carsharing = "Carsharing",
  Ridesharing = "Ridesharing",
  Fleet = "Fleet",
}
