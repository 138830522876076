import React from "react";

import { ArrowRight, CheckCircle3, XCircle } from "#components/base/Icon/Icon";
import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";
import { allUSStates as usStates } from "#constants/allUSStates";
import { parseCentsFromCurrencyInputString } from "#util/number/number";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";
import { Card } from "#v2-components/molecules/Card/Card";

import { StepEnum } from "../ApplyPage";
import styles from "../ApplyPage.module.scss";
import { LoanClassV2, VehicleUsage } from "../ApplyPage.types";
import { LoanClassCard } from "./LoanClass";

const renderIcon = (condition: boolean) => {
  return condition ? (
    <CheckCircle3 height={18} width={18} />
  ) : (
    <XCircle height={18} width={18} />
  );
};

const Ineligible = () => {
  const applyContext = useApplyContext();
  const hasAtLeastOneVehicle =
    (applyContext?.hostingData?.numEVs || 0) +
      (applyContext?.hostingData?.numICEs || 0) >
    0;
  const hasHostedFor6Months =
    (applyContext?.hostingData?.years || 0) * 12 +
      (applyContext?.hostingData?.months || 0) >=
    6;

  const meetsMinRevenueRequirement =
    parseCentsFromCurrencyInputString(
      applyContext?.hostingData?.avgRevenue || "",
    ) > 120000; // In Cents

  const checkValidState = (submittedState: string | undefined) => {
    const state = usStates.find(
      (usState) => usState.abbreviation === submittedState?.toUpperCase(),
    );
    if (
      submittedState &&
      state?.supportsDirectPurchase &&
      state?.supportsRefinance
    ) {
      return true;
    }
    return false;
  };
  const meetsStateRequirement = checkValidState(
    applyContext.hostingData?.state,
  );
  const majorityOwner = applyContext?.hostingData?.majorityOwner;
  const badPlatform =
    applyContext?.hostingData?.platform === undefined &&
    applyContext?.hostingData?.majorityOwner === undefined;

  return (
    <div className={styles.ineligible}>
      <h2>
        {badPlatform
          ? "Business applications are only available for vehicle hosting with Turo or Getaround"
          : "Sorry, you're not eligible to continue"}
      </h2>
      <div className={styles.cardContainer}>
        <Card>
          <div className={styles.cardContent}>
            <h3>
              You must meet all criteria to be eligible for vehicle hosting
            </h3>
            {!meetsStateRequirement ? (
              <span className={styles.requirementRow}>
                {renderIcon(meetsStateRequirement)}
                {applyContext.hostingData?.state} is not currently supported at
                this time
              </span>
            ) : null}
            <span className={styles.requirementRow}>
              {renderIcon(hasAtLeastOneVehicle && hasHostedFor6Months)} Hosted
              at least one vehicle for six months or more with Turo or Getaround
            </span>
            <span className={styles.requirementRow}>
              {renderIcon(meetsMinRevenueRequirement)} Generate at least $1,200
              per month in hosting revenue
            </span>
            {majorityOwner !== undefined && (
              <span className={styles.requirementRow}>
                {renderIcon(majorityOwner)} Majority owner of an LLC business
                used for vehicle hosting
              </span>
            )}
            <div className={styles.contentDivider} />
            <div className={styles.bottomContainer}>
              <span className={styles.bodyText}>
                Join the waitlist to be notified when loans for carsharing
                businesses are available in your state.
              </span>
              <span className={styles.bodyText}>
                <a
                  href="https://tenetenergy.typeform.com/to/u3Qy7Hvz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <text className={styles.text}> Join waitlist</text>
                </a>
                <a
                  href="https://tenetenergy.typeform.com/to/u3Qy7Hvz"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <ArrowRight size={22} style={{ color: "#0C5A93" }} />
                </a>
              </span>
            </div>
          </div>
        </Card>
      </div>
      <LoanClassCard
        cy="apply-personal-ineligible"
        onClick={() => {
          window?.analytics?.track("APPLY_SWITCH_TO_PERSONAL");
          applyContext.setProvider({
            ...applyContext,
            loanClass: LoanClassV2.Individual,
            isEligibleForHosting: undefined,
            use: VehicleUsage.Personal,
          });

          applyContext.setNavigationHistory([
            StepEnum.LOAN_TYPE,
            StepEnum.LOAN_CLASS,
          ]);
        }}
        buttonVariant={ButtonVariant.DARK_OUTLINE}
        buttonLabel="Get pre-approved"
        imageSrc="/img/apply/apply-personal.png"
        title="Switch to a personal loan application instead?"
        subtitle="Apply to finance a vehicle for personal use with your own credit"
      />
    </div>
  );
};

export default Ineligible;
