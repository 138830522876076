import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useApplyContext } from "#components/provider/ProvidersOrchestration/Apply";

import { StepEnum } from "./ApplyPage";
import { LoanClassV2, VehicleUsage } from "./ApplyPage.types";

export const useGetNavigationFn = ({
  setStep,
  sendToCorrectFlow,
}: {
  setStep: (step: StepEnum) => void;
  sendToCorrectFlow: () => void;
}) => {
  const applyContext = useApplyContext();
  const router = useRouter();
  const [canRedirectOut, setCanRedirectOut] = useState(false);
  useEffect(() => {
    if (router.query?.noRedirect === "true") {
      setCanRedirectOut(false);
    } else {
      setCanRedirectOut(true);
    }
  }, [router]);

  return () => {
    const { loanClass, loanType, use, hostingData, isEligibleForHosting } =
      applyContext;

    // Class
    if (loanClass) {
      if (isEligibleForHosting === false) {
        // Not undefined, explicitly false
        setStep(StepEnum.INELIGIBLE);
        return;
      }
      // Class - Individual
      if (loanClass === LoanClassV2.Individual) {
        if (loanType) {
          // Loan Type - Defined
          if (use) {
            // Use - Defined
            // Carsharing
            if (use === VehicleUsage.Carsharing) {
              // Use - Carsharing
              // Eligibility
              setStep(StepEnum.HOSTING_ELIGIBILITY);
            }
            // Personal
            if (use === VehicleUsage.Personal) {
              if (canRedirectOut) {
                sendToCorrectFlow();
              }
            }
          } else {
            // Use - Undefined
            setStep(StepEnum.USE);
          }
        } else {
          // Loan Type - Undefined
          setStep(StepEnum.LOAN_TYPE);
        }
      }
      // Class - Business
      if (loanClass === LoanClassV2.Commercial) {
        // If no hosting data, hit business eligibility page
        if (!hostingData?.avgRevenue) {
          setStep(StepEnum.BUSINESS_ELIGIBILITY);
        }
        // If has hosting data and is eligible
        if (hostingData && isEligibleForHosting) {
          // If no use, hit use
          if (!use) {
            setStep(StepEnum.USE);
            // If no loan type, hit loan type page
          } else if (!loanType) {
            setStep(StepEnum.LOAN_TYPE);
            // If no platform, hit platform
          } else if (!hostingData.platform) {
            setStep(StepEnum.PLATFORM);
          }
          // All good, send to correct flow
          if (canRedirectOut) {
            sendToCorrectFlow();
          }
        }
        // If is not eligible or has selected Not a majority owner
        if (hostingData && hostingData.majorityOwner === false) {
          setStep(StepEnum.INELIGIBLE);
        }
      }
    } else {
      setStep(StepEnum.LOAN_CLASS);
    }
  };
};
