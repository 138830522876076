/** ******************************************************************************
Create Autopay
 * creates an AutopayConfig and LoanApplicationAutopay join
 ******************************************************************************* */

import { PaymentDayOfMonth } from "#api.lms/lmsDto.types";
import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCreateAutopay } from "./client.types";

const createAutopay: (
  appId: string,
  dayOfMonth: PaymentDayOfMonth,
  paymentMethodId: string,
  overrideAmountCents?: number,
) => Promise<{
  response: null | HTTPResponse<HTTPCreateAutopay>;
  error: null | HTTPResponse<HTTPCreateAutopay>;
}> = async (appId, dayOfMonth, paymentMethodId, overrideAmountCents) => {
  const response = await makeRequest<HTTPCreateAutopay>(
    HTTPMethod.POST,
    restPaths.application(appId).payments.createAutopay,
    {
      body: {
        dayOfMonth,
        paymentMethodId,
        overrideAmountCents,
        paymentSetting: "MonthlyPaymentAmount",
      },
    },
    true,
    "los",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default createAutopay;
