import { useState } from "react";

import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import styles from "../ApplyPage.module.scss";
import { HostingPlatform } from "../ApplyPage.types";
import { defaultPlatformOptions, PlatformSelector } from "./HostingEligibility";

const Platform = ({
  onSubmit,
}: {
  onSubmit: (platform: HostingPlatform) => void;
}) => {
  const [platform, setPlatform] = useState<HostingPlatform | undefined>(
    undefined,
  );
  return (
    <div className={styles.hostingEligibility}>
      <h1>Which platform are you hosting with?</h1>
      <PlatformSelector
        setPlatform={setPlatform}
        platform={platform}
        options={defaultPlatformOptions.map((option) => {
          if (option.platform === undefined) {
            return {
              ...option,
              title: "I'm not planning on hosting this vehicle",
            };
          }
          return option;
        })}
      />
      <Button
        data-cy="hosting-platform-submit"
        variant={ButtonVariant.DARK_FILLED}
        className={styles.continueButton}
        label="Continue"
        disabled={!platform}
        onClick={() => {
          if (platform) {
            onSubmit(platform);
          }
        }}
      />
    </div>
  );
};

export default Platform;
