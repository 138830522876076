import React, { forwardRef } from "react";
import { Control, useController } from "react-hook-form";

import CurrencyInput from "#components/base/CurrencyInput/CurrencyInput";
import Text from "#components/base/Text/Text";
import { flex, layout } from "#styles";

import { Props } from "./CurrencyInputField.types";

const CurrencyInputField = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      errors,
      label,
      control,
      name,
      "aria-label": ariaLabel,
      ...otherProps
    },
    ref,
  ) => {
    const { field } = useController({ control: control as Control, name });
    const hasError = !!errors?.[field.name];

    return (
      <div className={className} css={[flex.flexStartVertical, layout.gap(4)]}>
        {label && (
          <Text as="label" htmlFor={field.name}>
            {label}
          </Text>
        )}

        <div css={[flex.flexStartVertical, layout.gap(4)]}>
          <CurrencyInput
            error={hasError}
            name={field.name}
            ref={ref}
            valueInCents={field.value as number}
            onBlur={field.onBlur}
            onNumberChange={(...args) => {
              return field.onChange(...args);
            }}
            {...otherProps}
            aria-label={ariaLabel}
          />
          {hasError && (
            <Text color="textNegative" variant="body2">
              {errors?.[name]?.message}
            </Text>
          )}
        </div>
      </div>
    );
  },
);

export default CurrencyInputField;
