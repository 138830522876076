import { PaymentDateSetting, PaymentSetting } from "#api.lms/client.types";
import { Vehicle } from "#api.los/client.types";

export enum LoanPaymentStatus {
  AllocationFailed = "AllocationFailed",
  Applied = "Applied",
  Reversed = "Reversed",
  Failed = "Failed",
  Pending = "Pending",
  Success = "Success",
  Unapplied = "Unapplied",
}

export enum PaymentType {
  Credit = "credit",
  Payment = "payment",
}

export interface LoanPaymentAllocation {
  amountCents: number;
  createdAt: Date;
  externalLmsPaymentId: string;
  status: LoanPaymentStatus;
  paymentPrincipal?: number;
  paymentInterest?: number;
  paymentFees?: number;
  type: PaymentType;
}

export interface LoanDetailSnapshot {
  allocatedPayments: LoanPaymentAllocation[];
  amountPaidCents: number;
  daysPastDue: number;
  finalPaymentAmountCents: number;
  payoffAmountCents: number;
  previousPaymentAmountCents: number | null;
  previousPaymentDate: Date | null;
  remainingPrincipalCents: number;
  remainingTermMonths: number;
  totalAmountDueCents: number;
  upcomingPaymentAmountCents: number;
  upcomingPaymentDate: Date;
  totalFeesPaidCents: number;
  totalInterestPaidCents: number;
  totalPrincipalPaidCents: number;
}

export interface LoanDetailsOutputDto {
  apr: number;
  autopayConfiguration: AutopayOutputDto;
  currentSnapshot: LoanDetailSnapshot;
  deferredPaymentAmountCents: number;
  externalLmsLoanId: string;
  id: string;
  loanApplicationId: string;
  monthlyPaymentAmountCents: number;
  offerId: string;
  principalAmountCents: number;
  status: string;
  termMonths: number;
  userId: string;
  totalCostEstimateCents: number;
  flags: {
    isDelinquent: boolean;
    autopayEnabled: boolean;
    inManualAutopaySetup: boolean;
  };
  vehicle?: Vehicle;
  vin: string;
}

export interface AutopayOutputDto {
  id: string;
  nickname: string;
  overrideDay: PaymentDayOfMonth;
  overrideAmountCents: number;
  paymentDateSetting: PaymentDateSetting;
  paymentMethodId: string;
  paymentSetting: PaymentSetting;
}

// Don't change order of this enum:
export enum PaymentDayOfMonth {
  First = "First",
  Second = "Second",
  Third = "Third",
  Fourth = "Fourth",
  Fifth = "Fifth",
  Sixth = "Sixth",
  Seventh = "Seventh",
  Eighth = "Eighth",
  Ninth = "Ninth",
  Tenth = "Tenth",
  Eleventh = "Eleventh",
  Twelfth = "Twelfth",
  Thirteenth = "Thirteenth",
  Fourteenth = "Fourteenth",
  Fifteenth = "Fifteenth",
  Sixteenth = "Sixteenth",
  Seventeenth = "Seventeenth",
  Eighteenth = "Eighteenth",
  Nineteenth = "Nineteenth",
  Twentieth = "Twentieth",
  TwentyFirst = "TwentyFirst",
  TwentySecond = "TwentySecond",
  TwentyThird = "TwentyThird",
  TwentyFourth = "TwentyFourth",
  TwentyFifth = "TwentyFifth",
  // We only support payment dates through the 25th to allow 3 days for payment resolution before EOM.
}

export enum PaymentDayOfMonthShortened {
  Eighteenth = "18th",
  Eighth = "8th",
  Eleventh = "11th",
  Fifteenth = "15th",
  Fifth = "5th",
  First = "1st",
  Fourteenth = "14th",
  Fourth = "4th",
  Nineteenth = "19th",
  Ninth = "9th",
  Second = "2nd",
  Seventeenth = "17th",
  Seventh = "7th",
  Sixteenth = "16th",
  Sixth = "6th",
  Tenth = "10th",
  Third = "3rd",
  Thirteenth = "13th",
  Twelfth = "12th",
  Twentieth = "20th",
  TwentyEighth = "28th",
  TwentyFifth = "25th",
  TwentyFirst = "21st",
  TwentyFourth = "24th",
  TwentySecond = "22nd",
  TwentySeventh = "27th",
  TwentySixth = "26th",
  TwentyThird = "23rd",
}
