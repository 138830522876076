/** ******************************************************************************
Update Autopay
 * updates a recurring payment
 ******************************************************************************* */

import { HTTPUpdateAutopay, PaymentSetting } from "#api.lms/client.types";
import { PaymentDayOfMonth } from "#api.lms/lmsDto.types";
import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

const updateAutopay: ({
  loanId,
  dayOfMonth,
  paymentMethodId,
  overrideAmountCents,
  paymentSetting,
}: {
  loanId: string;
  dayOfMonth: PaymentDayOfMonth;
  paymentMethodId: string;
  overrideAmountCents?: number;
  paymentSetting?: PaymentSetting;
}) => Promise<{
  response: null | HTTPResponse<HTTPUpdateAutopay>;
  error: null | HTTPResponse<HTTPUpdateAutopay>;
}> = async ({
  loanId,
  dayOfMonth,
  paymentMethodId,
  overrideAmountCents,
  paymentSetting,
}) => {
  const response = await makeRequest<HTTPUpdateAutopay>(
    HTTPMethod.PUT,
    restPaths.payments(loanId).updateAutopay,
    {
      body: {
        dayOfMonth,
        paymentMethodId,
        overrideAmountCents: overrideAmountCents || 0,
        paymentSetting:
          paymentSetting || (overrideAmountCents ? "Override" : "AmountDue"),
      },
    },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default updateAutopay;
