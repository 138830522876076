import { useEffect } from "react";

import useDebounce from "#hooks/useDebounce/useDebounce";

/**
 * This hook returns an object containing the height and width values for the
 * current size of the viewport. If the user resizes the browser window, it will
 * return updated values. These values are debounced to avoid unnecessarily
 * frequent updates.
 */
function useViewportSize() {
  const innerHeight = typeof window !== "undefined" ? window.innerHeight : 0;
  const innerWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  const [height, setHeight] = useDebounce<undefined | number>(innerHeight, 100);
  const [width, setWidth] = useDebounce<undefined | number>(innerWidth, 100);

  const handleResize = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
    // Empty deps to ensure eventlistener is only inititialized on mount.
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { height, width };
}

export default useViewportSize;
