/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import "#util/validators/extensions";
import "react-singleton-context";
import "../../public/global.css";
import "../../public/toasts.css";

import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import noop from "lodash/noop";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import random from "random";
import React, { useEffect, useState } from "react";
import { Intercom, LiveChatLoaderProvider } from "react-live-chat-loader";

import { getAuth } from "#auth/utils";
import Wide from "#components/layouts/Wide/Wide";
import {
  InitialPageInfoProvider,
  useInitialPageInfo,
} from "#components/provider/InitialPageInfo";
import { ExperimentProvider } from "#components/provider/ProvidersOrchestration/ExperimentProvider/ExperimentProvider";
import { ReadyProvider, useReadyContext } from "#components/provider/Ready";
import {
  RouterProvider,
  useRouterContext as useRouter,
} from "#components/provider/Router";
import { useConsumeQuery } from "#components/provider/Router.utils";
import { ModalProvider } from "#components/provider/UIProviders/ModalProvider/ModalProvider";
import ClientOnly from "#components/util/ClientOnly/ClientOnly";
// import useNYRedirect from "#hooks/useNYRedirect";
import { REFERRAL_QUERY_PARAM } from "#constants";
import { meta } from "#constants/meta";
import useInputType from "#hooks/useInputType/useInputType";
import { getGoogleTagSnippet } from "#scripts";
import ReferralService from "#services/ReferralService";
import { globalThemeStyles } from "#themes";
import interperetAndMessageDocusignCallback from "#util/docusignEmbedAlerter";
import { env } from "#util/env";

if (typeof window !== "undefined") {
  if (window.location.pathname.includes("undefined")) {
    window.location = "/" as unknown as Location;
  }
}

/*
 * There are certain pages we do not want to auto-submit on enter keypress.
 * Specifically pages that have a mandatory disclosue/term selection process.
 * We do this to prevent accidental submission of the form.
 * Note that tab/select will still function here, so this will not
 * break these forms for screen readers.
 */
const NO_AUTOSUBMIT_STEPS = [
  "sign-in-or-create-account-button",
  "boarding-sign-in-or-create-account-button",
];

/* ***************** GLOBAL EVENT SETUP *********************** */
if (typeof window !== "undefined") {
  window.addEventListener("keypress", (event) => {
    const { key, code: keyCode } = event;
    if (key === "Enter" || keyCode === "13") {
      const submissionButtonExplicit =
        window.document.querySelector("[defaultbutton]");
      if (
        submissionButtonExplicit &&
        !NO_AUTOSUBMIT_STEPS.includes(submissionButtonExplicit.id)
      ) {
        event.preventDefault();
        (submissionButtonExplicit as HTMLButtonElement).click();
        return;
      }
      const submissionButtonImplicit =
        window.document.querySelectorAll('[type="submit"]');
      if (submissionButtonImplicit.length) {
        const finalButton =
          submissionButtonImplicit[submissionButtonImplicit.length - 1];
        event.preventDefault();
        if (!NO_AUTOSUBMIT_STEPS.includes(finalButton.id)) {
          (finalButton as HTMLButtonElement).click();
          return;
        }
      }
      if (document.activeElement?.nodeName === "BUTTON") {
        (document.activeElement as HTMLButtonElement).click();
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const existingSettings = window.intercomSettings;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.intercomSettings = {
    ...existingSettings,
    api_base: "https://api-iam.intercom.io",
    app_id: "icxn6an9",
  };
}
/* ***************** </ GLOBAL EVENT SETUP > *********************** */

/* ***************** EMBEDDED SETUP *********************** */
const isEmbedded = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();
/* ***************** </ EMBEDDED SETUP > *********************** */

// import AuthProvider from "#components/provider/AuthProvider/AuthProvider";
const AuthProvider = dynamic(
  () => import("#components/provider/AuthProvider/AuthProvider"),
);
const ParallaxProvider = dynamic(
  () => import("#components/util/ParallaxWrapper"),
);
const ToastContainer = dynamic(() => import("#components/util/ToastWrapper"));

const REFERRAL_PARAM = REFERRAL_QUERY_PARAM;

const AuthTrigger = ({ children }: { children: React.ReactNode }) => {
  const [setup, setSetup] = useState<boolean>(false);
  const { data: ready, update: setReady } = useReadyContext();
  const { refresh } = useInitialPageInfo();

  /* MPR, 2023/12/11: If you are looking for the intercom side effect setting its identity
    it exist in src/api.los/initializePage.ts */

  useEffect(() => {
    if (!setup) {
      (async () => {
        const auth = await getAuth();
        auth.onIdTokenChanged(() => {
          /* MPR, 2022/11/5 The lack of an await here may be an issue */
          /* MPR, 2023/11/13: Well well well if it isn't my own hubris */
          setReady?.(true);

          /* this IIFE exists for integration tests. Leave it alone. */
          (async () => {
            /* its fine for this to be in prod, we just dont want to spare the import */
            if (!env.isProduction) {
              const { signInWithEmailAndPassword } = await import(
                "firebase/auth"
              );
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              window._tenet = window._tenet || {};
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              window._tenet.login = signInWithEmailAndPassword.bind(null, auth);
            }
          })();
          refresh();
        });
        setSetup(true);
      })();
    }
  }, []);
  if (!ready) {
    return <span />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const NullComponent: typeof LiveChatLoaderProvider = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
);

const Providers: React.FC = ({ children }) => {
  const IntercomShim = (() => {
    if (typeof window !== "undefined") {
      if (
        window.location.pathname.includes("browse") ||
        window.location.pathname.includes("budget")
      ) {
        return NullComponent;
      }
      return LiveChatLoaderProvider;
    }
    return NullComponent;
  })();

  return (
    <RouterProvider>
      <ParallaxProvider>
        <IntercomShim providerKey="icxn6an9" provider="intercom" idlePeriod={0}>
          <AuthProvider>
            <ReadyProvider>
              <InitialPageInfoProvider>
                <AuthTrigger>
                  <ExperimentProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </ExperimentProvider>
                </AuthTrigger>
              </InitialPageInfoProvider>
            </ReadyProvider>
            <ToastContainer />
          </AuthProvider>
        </IntercomShim>
      </ParallaxProvider>
    </RouterProvider>
  );
};

/* MPR, 2023/1/12: Ok. So this weird thing is going to allow us to call
 * analytics methods before we have loaded the analytics library. It
 * mocks the shape of the library, and repeatedly calls itself until
 * window.analytics is dynamically replaced, allowing the actual
 * method to be called after the fact. */
const analyticsPreloadShim = {
  /* MPR, 2023/1/12: If adding new methods, use this function as
   * a template for non-promise returning methods */
  track: (...args: any) => {
    window.setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.analytics?.track(...args);
    }, 500);
  },
  identify: (...args: any) => {
    window.setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.analytics.identify(...args);
    }, 500);
  },
  page: (...args: any) => {
    window.setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.analytics.page(...args);
    }, 100);
  },
  /* MPR, 2023/1/12: this is gunna be a doozy */
  /* MPR, 2023/1/12: Use this method (ready) as a template
   * for promise-returning methods */
  ready: (...args: any) => {
    return new Promise((s, f) => {
      try {
        window.setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          s(window.analytics.ready(...args));
        }, 500);
      } catch (e) {
        f(e);
      }
    });
  },
};

if (typeof window !== "undefined") {
  window.analytics = new Proxy(analyticsPreloadShim, {
    get(target, prop) {
      if (Object.keys(analyticsPreloadShim).includes(prop as string)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params, @typescript-eslint/no-unsafe-return
        return Reflect.get(...arguments);
      }
      throw new Error(
        `Attempted early access to analytics property ${
          prop as string
        }. You need to add the method to the analyticsPreloadShim in _app.tsx.`,
      );
    },
  });
}

const FallbackWideLayout = (page: React.ReactNode) => (
  <Wide showHeader={false}>
    <ClientOnly>{page}</ClientOnly>
  </Wide>
);

const ExternalScriptLoader = ({ children }: { children: React.ReactNode }) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [visualidInitialized, setVisualidInitialized] = useState(false);
  const [externalScriptDelayComplete, setExternalScriptDelayComplete] =
    useState(false);
  const { loading } = useInitialPageInfo();

  useEffect(() => {
    if (visualidInitialized) return noop;
    const interval = setInterval(() => {
      if (typeof window !== "undefined") {
        // @ts-ignore
        if (window.vpixel) {
          // @ts-ignore
          window.vpixel.piximage("f0ed426d-8abb-4a4f-9aef-88caa5e1e632");
          setVisualidInitialized(true);
          clearInterval(interval);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!loading && !externalScriptDelayComplete) {
      /* MPR, 2023/1/13: I don't like this approach, but if were past the point
       * of successfully making API calls and we are still being dinged for loading
       * fullstory (et al) I don't see anything else to trigger on to delay
       * 4 seconds is arbitrary but was the shortest delay I could test to
       * achieve the desired result. */
      const timeout = setTimeout(() => {
        setExternalScriptDelayComplete(true);
      }, 4000);
      return () => clearTimeout(timeout);
    }
    return () => undefined;
  }, [loading]);

  useEffect(() => {
    if (!loading && !hasInitialized && externalScriptDelayComplete) {
      (async () => {
        const Segment = await import("@segment/analytics-next");
        const analytics = Segment.AnalyticsBrowser.load({
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY!,
        });

        analytics.ready().then(() => {
          /* MPR, 2023/1/4: All the ts-ignoring is ugly, but we'll never use any
           * of this elsewhere, so it's pointless to go to the effort of defining it
           */
          const MAX_FS_RETRIES = 10;
          const setupFSSentry = (retries = 0) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            const fullstorySession = window.FS.getCurrentSessionURL() as string;
            if (fullstorySession != null) {
              Sentry.setContext("fullstory", {
                session: fullstorySession,
              });
              // eslint-disable-next-line no-console
              console.info("fullstory link success");
            } else if (retries < MAX_FS_RETRIES) {
              setTimeout(() => {
                setupFSSentry(retries + 1);
              }, 500);
            }
          };

          if (typeof window !== "undefined") {
            try {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              if (window.FS && window.FS.getCurrentSessionURL) {
                setupFSSentry();
              } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window._fs_ready = () => {
                  setupFSSentry();
                };
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn("fullstory not linked to sentry");
            }
          }
        });

        if (typeof window !== "undefined") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          window.analytics = analytics;
        }

        setHasInitialized(true);
      })();
    }
  }, [loading, externalScriptDelayComplete]);

  const showIntercom = (() => {
    if (typeof window !== "undefined") {
      return (
        !window.location.pathname.includes("browse") &&
        !window.location.pathname.includes("budget")
      );
    }
    return false;
  })();

  return (
    <>
      {hasInitialized && (
        <>
          {env.isProduction && (
            <>
              <Script
                id="gtag-loader"
                src="https://www.googletagmanager.com/gtag/js?id=G-LWHWWVZJ6Z"
              />
              <Script
                id="gtag-snippet"
                dangerouslySetInnerHTML={{
                  __html: getGoogleTagSnippet(),
                }}
              />
              <Script
                id="visuald"
                type="text/javascript"
                src="https://pixel.visitiq.io/vpixel.js"
              />
            </>
          )}
          {showIntercom && <Intercom color="#367198" />}
        </>
      )}
      {children}
    </>
  );
};

const TenetApp = ({
  Component,
  pageProps,
}: AppProps & { Component: NextPageWithLayout }) => {
  const { query, pathname, asPath } = useRouter();
  const referralCode = query[REFERRAL_PARAM] as string;
  const [globalStyleInit, setGlobalStyleInit] = useState(false);
  const [detectedTenetEmbeddable, setDetectedTenetEmbeddable] = useState(false);
  const [parentDidPostPropsCount, setParentDidPostPropsCount] = useState(0);

  // Bucket the user for unauthenticated experiments
  useEffect(() => {
    const bucket = Cookies.get("bucket");
    if (!bucket) {
      Cookies.set("bucket", `${random.int(1, 10000)}`);
    }
  }, []);

  // If the chat querystring is passed, open intercom automatically
  useConsumeQuery("chat", (val: string) => {
    if (typeof window !== "undefined" && val) {
      // try to open the chat window for 30 seconds, every half second
      let count = 60;
      const windowOpen = setInterval(() => {
        count -= 1;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.Intercom) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.Intercom("show");
          clearInterval(windowOpen);
        } else if (count <= 0) {
          clearInterval(windowOpen);
        }
      }, 500);
    }
  });

  // send NY users to NY landing page, once
  // useNYRedirect();

  // async load global styles
  useEffect(() => {
    if (!globalStyleInit) {
      (async () => {
        if (
          pathname.includes("new-loan") ||
          pathname.includes("refi") ||
          pathname.includes("refinance")
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await import("../../public/loan-global.css");
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await import("../../public/landing-global.css");
        }
      })();
      setGlobalStyleInit(true);
    }
  }, []);

  // setup segment analytics
  useEffect(() => {
    window.analytics.page();
  }, [pathname]);

  // setup iframe checks for embedded calculator
  useEffect(() => {
    /* because we cannot guarentee the existance of the xprops object
     * we have no choice but to poll. */
    if (isEmbedded) {
      const pollForXprops = setInterval(() => {
        if (window.xprops) {
          setParentDidPostPropsCount(parentDidPostPropsCount + 1);
          clearInterval(pollForXprops);
        }
      }, 1000);
      return () => clearInterval(pollForXprops);
    }
    return noop;
  }, []);

  const resizeWithXprops = () => {
    document.body.style.width = window.xprops.width as string;
    document.body.style.height = window.xprops.height as string;
    window.xprops.resize({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      width: window.xprops.width,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      height: window.xprops.height,
    });
  };

  // if we are in a valid embedded context, frame sizes will be provided by the implementor
  // this updates the size of our document from the provided sizes, because our initial pre-js size will be wrong
  useEffect(() => {
    /* trigger an update of an embedded component when the parent posts props.
     * does nothing in non-embedded contexts */
    let listener = () => {
      setParentDidPostPropsCount(parentDidPostPropsCount + 1);
      resizeWithXprops();
    };
    if (typeof window !== "undefined") {
      window.xprops?.onProps(listener);
    }
    return () => {
      listener = noop;
    };
  }, []);

  const embeddedScriptProps = (typeof window === "undefined" ? {} : window)
    ?.xprops;
  /* as we add embedded functions, they should be listed here as a whitelist */
  const isValidEmbed = embeddedScriptProps?.tenetCalculator as boolean;

  useEffect(() => {
    if (isEmbedded && !detectedTenetEmbeddable) {
      if (isValidEmbed) {
        resizeWithXprops();

        try {
          window.xprops._internal();
          window.xprops.onLoad();
        } catch (e) {
          window.xprops.onError();
        }

        setDetectedTenetEmbeddable(true);
      }
    }
  }, [embeddedScriptProps, isValidEmbed, parentDidPostPropsCount]);

  // If a user arrives with a referral code we save that to local storage
  // and retrieve it to submit when they create an account.
  if (referralCode) {
    localStorage.setItem(REFERRAL_PARAM, referralCode);
  }

  // If a page Component does not have a custom layout,
  // assume that it is a client-rendered page.
  const getLayout = Component.getLayout || FallbackWideLayout;

  globalThemeStyles();
  useInputType();
  const url =
    // eslint-disable-next-line no-nested-ternary
    typeof window === "undefined"
      ? ""
      : window.document.location.ancestorOrigins != null
      ? window.document.location.ancestorOrigins[0]
      : document.referrer;
  // we allow exactly two scenarios for embedding: tenet embedded apps like calculator, and our contentful preview
  if (
    isEmbedded &&
    !detectedTenetEmbeddable &&
    url !== "https://app.contentful.com"
  ) {
    // this is a side effect = before we block the embed, if this is the docusign callback, fire its event
    if (typeof window !== "undefined") {
      const isDocusignCallback =
        window.location.pathname.includes("/signing-complete/");
      if (isDocusignCallback) {
        interperetAndMessageDocusignCallback();
      }
    }

    // hide all content from invalid embeds
    return null;
  }

  const canonicalUrl = (() => {
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      if (path === "/") return "";
      return `https://tenet.com${path.endsWith("/") ? path : `${path}/`}`;
    }
    if (asPath === "/") return "";
    const path = asPath.split("?")[0];
    return `https://tenet.com${path.endsWith("/") ? path : `${path}/`}`;
  })();

  const referralCodeFromStorage = ReferralService.getReferralCode();
  const defaultMetaTitle = "Fast, affordable financing from trusted EV experts";
  const defaultMetaDescription =
    "Tenet is building the financial platform of the global energy transition.";
  const defaultOGMetaImage = "https://tenet.com/meta-og-preview.png";
  const fallbackOGMetaImage = "https://tenet.com/meta-square-preview.png";
  const defaultTCMetaImage =
    referralCode == null && referralCodeFromStorage == null
      ? "https://tenet.com//meta-twitter-preview.png"
      : "https://images.ctfassets.net/w0ps5rolaaeh/70XPT1bAtdMVkRv3ayAwYe/18f55d9648a9f0a33c262bd71cd6c0c8/image__2_.png";

  const schemaOrgWebsiteStructuredData = [
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Tenet Energy",
      alternateName: "Tenet",
      url: "https://tenet.com/",
      provider: {
        "@type": "Organization",
        name: "Tenet Energy",
        logo: {
          "@type": "ImageObject",
          url: "https://tenet.com/img/logo.svg",
        },
      },
    },
  ];
  const schemaOrgOrganizationStructuredData = [
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      name: "Tenet Energy",
      logo: "https://tenet.com/img/logo.svg",
      url: "https://tenet.com/",
      description: defaultMetaDescription,
    },
  ];

  return (
    <>
      <Head>
        {/* meta defaults: these are intentionally hardcoded. do not make them network based */}
        {/* if you want them to be dynamic (e.g. contentful), override them on a page by page basis */}

        {/* ------ non-override properties: these are global and should not be changed ------ */}
        {/* note the lack of a key here - this should never be overridden */}
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        {/* MPR: I don't think url or domain are valid TC tags, but doesnt hurt I guess */}
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="twitter:domain" content="tenet.com" />
        <meta property="twitter:site" content="@tenetenergy" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Tenet Energy" />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaOrgWebsiteStructuredData),
          }}
        />
        <script
          key={meta.SO_STRUCTURED_ORG_DATA}
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaOrgOrganizationStructuredData),
          }}
        />

        {/* ------ page properties: these should be overriden when appropriate ------ */}
        {/* These are global defaults, that are overridden by key in lower components such as a blog post */}
        {/* common properties */}
        <title key={meta.W3_TITLE}>
          Tenet: EV Financing | Electric Car Loan
        </title>
        <meta
          key={meta.OG_TITLE}
          property="og:title"
          content={defaultMetaTitle}
        />
        <meta
          key={meta.TC_TITLE}
          name="twitter:title"
          content={defaultMetaTitle}
        />
        <meta
          key={meta.W3_DESCRIPTION}
          content={defaultMetaDescription}
          name="description"
        />
        <meta
          key={meta.OG_DESCRIPTION}
          property="og:description"
          content={defaultMetaDescription}
        />
        <meta
          key={meta.TC_DESCRIPTION}
          name="twitter:description"
          content={defaultMetaDescription}
        />
        <meta
          key={meta.TC_IMAGE}
          name="twitter:image"
          content={defaultTCMetaImage}
        />
        <meta
          key={meta.OG_IMAGE}
          property="og:image"
          content={defaultOGMetaImage}
        />
        <meta
          key={meta.OG_IMAGE_WIDTH}
          property="og:image:width"
          content="1085"
        />
        <meta
          key={meta.OG_IMAGE_HEIGHT}
          property="og:image:height"
          content="570"
        />
        <meta
          key={meta.OG_IMAGE_MIME}
          property="og:image:type"
          content="image/png"
        />
        <meta
          key={meta.OG_IMAGE_FALLBACK}
          property="og:image"
          content={fallbackOGMetaImage}
        />
        <meta
          key={meta.OG_IMAGE_WIDTH_FALLBACK}
          property="og:image:width"
          content="570"
        />
        <meta
          key={meta.OG_IMAGE_HEIGHT_FALLBACK}
          property="og:image:height"
          content="570"
        />
        <meta
          key={meta.OG_IMAGE_MIME_FALLBACK}
          property="og:image:type"
          content="image/png"
        />

        {/* uncommon properties */}
        {/* (music, video, etc) https://ogp.me/#types */}
        <meta key={meta.OG_TYPE} property="og:type" content="website" />
        {/* changes size and layout of card, see https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup */}
        <meta
          key={meta.TC_CARD}
          name="twitter:card"
          content="summary_large_image"
        />
      </Head>
      <Providers>
        {getLayout(
          <ExternalScriptLoader>
            <Component {...pageProps} />
          </ExternalScriptLoader>,
        )}
      </Providers>
    </>
  );
};

export default TenetApp;
