import { Popover } from "@headlessui/react";
import { createPortal } from "react-dom";

import Modal from "#components/base/Modal/Modal";
import Transition from "#components/util/Transition/Transition";
import useBreakpoint from "#hooks/useBreakpoint/useBreakpoint";
import useModalContext from "#hooks/useModalContext/useModalContext";
import usePopover from "#hooks/usePopover/usePopover";
import { flex, transition } from "#styles";

import * as css from "./Tooltip.styles";
import { Props } from "./Tooltip.types";

const bodyEl = typeof document === "undefined" ? undefined : document.body;

/**
 * Tooltips display informative text when users hover over, focus on, or tap an
 * element.
 */
const Tooltip = ({
  children,
  placement = "top",
  portalTarget,
  trigger,
  variant = "primary",
  ...otherProps
}: Props) => {
  const { modalEl } = useModalContext();
  const { setAnchorElement, setPopoverElement, popoverStyles } = usePopover({
    modifiers: [
      { name: "offset", options: { offset: [0, 16] } },
      {
        name: "flip",
        options: { fallbackPlacements: ["bottom", "right", "left"] },
      },
    ],
    placement,
    strategy: "absolute",
  });

  const currBreakpoint = useBreakpoint();
  const isMobileViewport = currBreakpoint === "tabletSmall";

  return (
    <Popover {...otherProps}>
      {({ close, open }) => (
        <>
          <Popover.Button
            css={[flex.flexCenter, css.trigger]}
            ref={setAnchorElement}
          >
            {trigger}
          </Popover.Button>

          {isMobileViewport ? (
            <Modal
              onClose={() => {
                close();
                return false;
              }}
              open={open}
              children={children}
            />
          ) : (
            bodyEl &&
            createPortal(
              <Popover.Panel
                ref={setPopoverElement}
                style={{ ...popoverStyles, zIndex: 101 }}
              >
                <Transition show={open}>
                  <div
                    css={[css.tooltip, transition.growFromCenter]}
                    cssProps={{ variant }}
                  >
                    {children}
                  </div>
                </Transition>
              </Popover.Panel>,
              portalTarget || modalEl || bodyEl,
            )
          )}
        </>
      )}
    </Popover>
  );
};

export default Tooltip;
