import { DealershipInfo } from "#components/partial/DealershipForm/DealershipForm.types";

// todo this should be moved to a provider
export const clearDealerInfo = () => {
  sessionStorage.removeItem("dealer-info");
};

// todo this should be moved to a provider
export const storeDealerInfo = (dealerInfo: DealershipInfo) => {
  sessionStorage.setItem("dealer-info", JSON.stringify(dealerInfo));
};
// todo this should be moved to a provider
/* eslint-disable consistent-return */
export const getDealerInfo = (): DealershipInfo | undefined => {
  const dealerString = sessionStorage.getItem("dealer-info");

  if (dealerString) {
    return JSON.parse(dealerString) as DealershipInfo;
  }
};
