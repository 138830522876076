/** ******************************************************************************
Get Active Loan Applicant ("LoanUser")
 * Retrieves the Applicant entity, which joins a "User" and a "LoanApplication"
 * hence "LoanUser" in DB
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPAutoVerifyIncomeRes, LOSAPIResponse } from "./client.types";

const autoVerifyIncome: ({
  applicationId,
  applicantId,
}: {
  applicationId: string;
  applicantId: string;
}) => LOSAPIResponse<HTTPAutoVerifyIncomeRes> = async ({
  applicationId,
  applicantId,
}) => {
  const response = await makeRequest<HTTPAutoVerifyIncomeRes>(
    HTTPMethod.GET,
    restPaths.application(applicationId).verifyIncome(applicantId),
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default autoVerifyIncome;
