/* eslint-disable @typescript-eslint/no-explicit-any */
import capitalize from "lodash/capitalize";
import noop from "lodash/noop";
import { useState } from "react";

import { Partner } from "#api.los/client.types";
import { LoanType } from "#components/partial/ApplyPage/ApplyPage.types";
import { ApplyConfig } from "#components/provider/ProvidersOrchestration/Apply";
import { useRouterContext } from "#components/provider/Router";
import { useConsumeQuery } from "#components/provider/Router.utils";

import { Page } from "./pageNavigationHelpers.page";

export { Page } from "./pageNavigationHelpers.page";

export const NEW_APPLICATION_URL = "/apply/";
export const NEW_STANDARD_INDIVIDUAL_REFINANCE_APPLICATION_URL = `/refinance/intent/?applyConfig=${ApplyConfig.STANDARD_NEW_LOAN}`;
export const NEW_STANDARD_INDIVIDUAL_PURCHASE_APPLICATION_URL = `/new-loan/intent/?applyConfig=${ApplyConfig.STANDARD_NEW_LOAN}`;
export const NEW_REFINANCE_APPLICATION_URL =
  "/apply/?loanClass=Individual&loanType=Refinance";
export const NEW_PURCHASE_APPLICATION_URL =
  "/apply/?loanClass=Individual&loanType=Purchase";

export enum PrimaryNavigationSection {
  "vehicles",
  "settings",
}
export enum SecondaryNavigationSection {
  "profile",
  "documents",
  "payments",
}

export enum LocationType {
  /* MPR, 2023/2/12: "landing" page routes are used as-is, using only their provided stub. These are effectively normal links. */
  "landing",
  /* MPR, 2023/2/12: "origination" page routes (the default) attempt to preserve the partner and product flow, e.g. /turo/new-loan/{stub} */
  "origination",
  /* MPR, 2023/2/12: "portal" page routes prepend 'dashboard', but do not otherwise modify the provided stub */
  "portal",
}

const pageInfo = [
  {
    key: Page.HOME,
    stub: "",
    type: LocationType.landing,
  },
  {
    key: Page.NEW_LOAN_REDIRECTOR,
    stub: "new-loan",
    type: LocationType.landing,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.APPLY,
    stub: "apply",
    type: LocationType.origination,
  },
  {
    key: Page.APPLY_REDIRECTOR,
    stub: "apply",
    type: LocationType.landing,
  },
  {
    key: Page.NEW_APPLICATION,
    stub: NEW_APPLICATION_URL,
    type: LocationType.landing,
  },
  {
    key: Page.NEW_PURCHASE_APPLICATION,
    stub: NEW_PURCHASE_APPLICATION_URL,
    type: LocationType.landing,
  },
  {
    key: Page.NEW_REFINANCE_APPLICATION,
    stub: NEW_REFINANCE_APPLICATION_URL,
    type: LocationType.landing,
  },
  {
    key: Page.REFI_REDIRECTOR,
    stub: "refinance",
    type: LocationType.landing,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.PORTAL_OVERVIEW,
    stub: "home",
    type: LocationType.portal,
  },
  {
    key: Page.PORTAL_SETTINGS,
    stub: "settings",
    type: LocationType.portal,
  },
  {
    key: Page.RESUME_LOAN_FORM,
    stub: "resume-loan",
    type: LocationType.origination,
  },
  {
    key: Page.START_APPLICATION,
    stub: "start-application",
    type: LocationType.origination,
  },
  {
    key: Page.SERVICE_UPDATE_FORM,
    stub: "service-update",
    type: LocationType.origination,
  },
  {
    key: Page.SIGNUP_FORM,
    stub: "sign-up",
    type: LocationType.origination,
  },
  {
    key: Page.EMAIL_VERIFICATION,
    stub: "email-verification",
    type: LocationType.origination,
  },
  {
    key: Page.PERSONAL_INFO_FORM,
    stub: "personal-info",
    type: LocationType.origination,
  },
  {
    key: Page.HOUSING_INFO_FORM,
    stub: "housing-info",
    type: LocationType.origination,
  },
  {
    key: Page.BUSINESS_INFO,
    stub: "business-info",
    type: LocationType.origination,
  },
  {
    key: Page.BUSINESS_OWNERSHIP,
    stub: "business-ownership",
    type: LocationType.origination,
  },
  {
    key: Page.EMPLOYMENT_INFO_FORM,
    stub: "employment-info",
    type: LocationType.origination,
  },
  {
    key: Page.APPLICATION_SUMMARY,
    stub: "application-summary",
    type: LocationType.origination,
  },
  {
    key: Page.SOFT_CREDIT_CONSENT,
    stub: "soft-credit-consent",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.SOCIAL_SECURITY_FORM,
    stub: "social-security-form",
    type: LocationType.origination,
  },
  {
    key: Page.SOFT_CREDIT_ERROR,
    stub: "soft-credit-error",
    type: LocationType.origination,
  },
  {
    key: Page.INTENT,
    stub: "intent",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.WAITLIST,
    stub: "waitlist",
    type: LocationType.origination,
  },
  {
    key: Page.ORDER_INFO,
    stub: "order-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.MMYT_MAKE,
    stub: "mmyt-make",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.MMYT_MODEL,
    stub: "mmyt-model",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.MMYT_TRIM,
    stub: "mmyt-trim",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.PREAPPROVAL_DECLINE,
    stub: "preapproval-decline",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.PREAPPROVAL_SUCCESS,
    stub: "preapproval-success",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.NEW_VEHICLE_INFO_FORM,
    stub: "new-vehicle-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.PASSTHROUGH_OFFERS,
    stub: "passthrough-offers",
    type: LocationType.origination,
  },
  {
    key: Page.OFFER_TERM_EDIT_FORM,
    stub: "offer-term-form",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  { key: Page.NO_LOANS, stub: "no-loans", type: LocationType.origination },
  {
    key: Page.OFFER_DEFERRED_PAYMENT_EDIT_FORM,
    stub: "offer-deferred-payment-form",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.CONGRATULATIONS,
    stub: "congratulations",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.FINAL_LOAN_SUMMARY,
    stub: "final-loan-summary",
    type: LocationType.origination,
  },
  {
    key: Page.HARD_CREDIT_FORM,
    stub: "hard-credit-form",
    type: LocationType.origination,
  },
  {
    key: Page.CONFIRMATION_FORM,
    stub: "confirmation-form",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.LOAN_CONTRACT,
    stub: "loan-contract",
    type: LocationType.origination,
  },
  {
    key: Page.LOAN_APP_COMPLETE,
    stub: "loan-app-complete",
    type: LocationType.origination,
  },
  {
    key: Page.REFI_REDIRECTOR,
    stub: "refinance",
    type: LocationType.landing,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_SOFT_CREDIT_CONSENT,
    stub: "soft-credit",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_VEHICLE_INFO_FORM,
    stub: "vehicle-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_VEHICLE_CONFIRMATION,
    stub: "vehicle-confirmation",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_PREVIOUS_LOANS_LIST,
    stub: "select-existing-loan",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_PREVIOUS_LOAN_INFO,
    stub: "previous-loan-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_LOAN_OFFERS_FORM,
    stub: "loan-offers",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
];

/* MPR, 2022/11/10: This const can be removed once all pages provide their own
 * navigation functions */
// Once the user passes certain steps in the wizard they
// should no longer be allowed to navigate back.
export const DIRECT_STEPS_WITH_BACK_DISABLED = [
  Page.REFI_VEHICLE_INFO_FORM,
  Page.EMAIL_VERIFICATION,
  Page.PERSONAL_INFO_FORM,
  Page.INTENT,
  Page.PASSTHROUGH_OFFERS,
  /** I commented this out because going backwards all the way
   * to the vin screen doesn't cause any issuses - Jordon 12/21/22 */
  // Page.CONFIRMATION_FORM,
];

/**
 * gives info about how and when to navigate to a given page
 * e.g. address stub, order, etc
 * @param page where we want to go (MPR: In the future, we should generalize the step to include all pages)
 * @returns
 */
export const getPageNavigationInfo = (page: Page) => {
  const pageInfoItem = pageInfo.find((v) => {
    return v.key === page;
  });
  const pageInfoIndex = pageInfo.findIndex((v) => {
    return v.key === page;
  });
  return {
    stub: pageInfoItem?.stub,
    key: pageInfoItem?.key,
    type: pageInfoItem?.type,
    flowSpecific: pageInfoItem?.flowSpecific,
    order: pageInfoIndex,
  };
};

/**
 * Gives back the partner and product we are currently in
 * This may only be needed at the very beginning of flows, while
 * still unauthenticated. After that point, we should be solidly
 * on a given partner+product pair, and this should come back
 * in the page initialization from the BE, which is an improvement
 * as this sort of string interperetation is brittle.
 * MPR, 2022/11/30: We should put some thought into how to generalize
 * this to include the dashboard as well
 * @param path the current url path
 */
export const getFlowSegments = (path: string) => {
  const segments = path.split("/");
  if (!segments[0]) {
    // disregard leading slash
    segments.shift();
  }
  if (!segments[segments.length - 1]) {
    // disregard trailing slash
    segments.pop();
  }
  if (segments.length > segments.filter((p) => !!p).length) {
    throw new Error("empty path segment. Some route has not been defined.");
  }
  const getNonPartnerSegment = (segment: string) => {
    switch (segment) {
      case "new-loan":
        return { product: "new-loan" };
      case "refi":
        return { product: "refi" };
      case "refinance":
        return { product: "refinance" };
      case "new-loan-turo":
        // legacy partner format
        return { product: "new-loan", partner: "turo" };
      case "dashboard":
        return { product: "dashboard" };
      case "examples":
        return { product: "examples" };
      case "claim":
        return { product: "claim" };
      case "apply":
        return { product: "default" };
      default:
        throw new Error("unable to parse url path. Unknown segment provided.");
    }
  };
  if (segments.length === 1) {
    // we are in the old form flow/new flow landing
    return getNonPartnerSegment(segments[0]);
  }
  if (segments.length === 2) {
    // we are either in partnerless app, or partnered old flow/new flow landing
    if (Partner[capitalize(segments[0])]) {
      // partnered old flow/new flow landing
      return {
        // MT, return partner string as lower case to prevent capitalizing in the url
        partner: segments[0].toLowerCase(),
        product: segments[1],
      };
    }
    // partnerless app
    return getNonPartnerSegment(segments[0]);
  }
  if (segments.length >= 3) {
    // partnered new nav flow
    return {
      // MT, return partner string as lower case to prevent capitalizing in the url
      partner: segments[0],
      product: segments[1],
    };
  }
  throw new Error("An unknown error occurred");
};

/* These need to match whatever ends up in the "match group" */
export enum FlowOptions {
  default,
  "new-loan",
  refinance,
  turo,
}

export const deleteQueryParam = (param: string) => {
  const params = new URLSearchParams(window.location.search);
  params.delete(param);
  window.history.replaceState(
    {},
    "",
    `${window.location.toString().split("?")[0]}?${params.toString()}`,
  );
};

interface HandlerConfig<ParamsType extends any[], Response> {
  flow: FlowOptions[];
  back?: () => void;
  next?: (response: Response) => void;
  send?: (...params: ParamsType) => Promise<Response>;
  delayNextOverride?: boolean;
}

/**
 * Requires router context.
 * takes an array of handlers configured for a variety of submission scenarios
 * e.g. refi vs direct, specific partners, partner vs non partner. Whatever
 * situation should be able to be covered. Feel free to expand the list.
 * Note that we limit our send handler to a single generic request and response
 * type - if your submission handlers vary that much, then you are probably
 * trying to cram more data into a form than is appropriate, and the pages
 * should just be separated.
 * @param handlers
 * @returns an object containing a "back" and "onSubmit handler". The onSubmit
 * handler is composed of a "send" function, which does the form submit, and
 * a "next" function, which handles what happens after the submission returns
 * with some default error handling. If you need finer grained control, send
 * and next are also returned and can be called independently.
 */
export const useHandlerFactory = <
  SubmitParams extends any[] = [],
  Response = undefined,
>(
  handlers: HandlerConfig<SubmitParams, Response>[],
) => {
  const { path, navigateTo } = useRouterContext();
  const { partner, product } = getFlowSegments(path);
  const [nextOverride, setNextOverride] = useState<string | null>(null);
  const [backOverride, setBackOverride] = useState<string | null>(null);
  useConsumeQuery("next", (param: string) => setNextOverride(param), {
    preserve: true,
  });
  useConsumeQuery("back", (param: string) => setBackOverride(param), {
    preserve: true,
  });

  /* MPR, 2023/1/24: Add to this array to include new possible flow groups */
  const matchGroup = [FlowOptions[product]];
  if (partner != null) {
    matchGroup.push(FlowOptions[partner]);
  }

  /* MPR, 2023/1/22: we select which handler to use based on the most specific condition
   * that we can match, so if we specify, for example, handlers for both ["refi"] and
   * ["turo", "refi"], the latter will be fired when both conditions match. If there
   * are two equally specific handlers specified, we will choose whichever is listed
   * first. We could also throw an error, but I don't suspect this will come up much */
  const defaultSend = handlers.find((h) => {
    return h.flow.includes(FlowOptions.default) && h.send != null;
  })?.send;
  const defaultBack = handlers.find((h) => {
    return h.flow.includes(FlowOptions.default) && h.back != null;
  })?.back;
  const defaultNextHandler = handlers.find((h) => {
    return h.flow.includes(FlowOptions.default) && h.next != null;
  });

  const findMatch = <T>(key: string) => {
    const result = handlers.reduce<null | {
      matchScore: number;
      fn: T;
      delayNext?: boolean;
    }>((a, h) => {
      const matchScore = h.flow.reduce((count, flow) => {
        if (matchGroup.includes(flow)) return count + 1;
        return count;
      }, 0);
      if (matchScore > 0) {
        if (h[key] != null && (a == null || matchScore > a.matchScore)) {
          return {
            matchScore,
            fn: h[key] as T,
            delayNext: h.delayNextOverride,
          };
        }
      }
      return a;
    }, null);
    return result;
  };

  const matchSend =
    findMatch<(...param: SubmitParams) => Promise<void>>("send")?.fn;
  const matchNextResult = findMatch<(param: Response) => void>("next");
  const matchBack = findMatch<() => void>("back")?.fn;

  if (!matchNextResult?.fn && !defaultNextHandler?.next) {
    throw new Error(
      "Page misconfigured - submission has no forward navigation logic.",
    );
  }
  if (
    !matchNextResult?.fn &&
    !matchSend &&
    !defaultNextHandler?.next &&
    !defaultSend
  ) {
    throw new Error(
      "Page misconfigured - submit button has no associated logic.",
    );
  }

  const emptySend = () => {
    return {} as Response;
  };

  const back = matchBack || defaultBack || undefined; // null "back" implies back should be hidden/disabled
  const send = matchSend || defaultSend || emptySend;
  const next = matchNextResult?.fn || defaultNextHandler?.next || noop; // noop will never be invoked as we error above in this case
  const delayNext = matchNextResult?.fn
    ? matchNextResult.delayNext
    : defaultNextHandler?.delayNextOverride;

  type backType = typeof back;

  const wrappedBack: backType = (() => {
    /* this has special meaning for the back button if we want to disable it */
    if (backOverride === Page.NONE) return undefined;
    if (back == null) return back;
    return () => {
      if (backOverride && backOverride !== "") {
        const foundPageInfo = getPageNavigationInfo(backOverride as Page);
        if (foundPageInfo.order !== -1) {
          // valid page string
          navigateTo(backOverride as Page, {
            query: { back: "", next: "" },
          }).then(() => {
            deleteQueryParam("next");
            deleteQueryParam("back");
          });
          return;
        }
        // assume it's a url
        window.location = decodeURIComponent(
          backOverride,
        ) as unknown as Location;
        return;
      }
      back();
    };
  })();
  type nextType = typeof next;
  const wrappedNext: nextType = (...args) => {
    if (nextOverride && nextOverride !== "" && !delayNext) {
      const foundPageInfo = getPageNavigationInfo(nextOverride as Page);
      if (foundPageInfo.order !== -1) {
        // valid page string
        navigateTo(nextOverride as Page, {
          query: { back: "", next: "" },
        }).then(() => {
          deleteQueryParam("next");
          deleteQueryParam("back");
        });
        return;
      }
      // assume it's a url
      window.location = decodeURIComponent(nextOverride) as unknown as Location;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    next(...args);
  };

  const onSubmit = async (...params: SubmitParams) => {
    try {
      // const submissionResult = await send.apply(null, [].concat(params));
      const submissionResult = await send(...params);
      wrappedNext(submissionResult);
    } catch (e) {
      wrappedNext({} as Response);
    }
  };

  return { back: wrappedBack, next: wrappedNext, send, onSubmit };
};
