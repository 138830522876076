import useViewportSize from "#hooks/useViewportSize/useViewportSize";
import { breakpoints } from "#themes";

const breakpointKeys = Object.keys(
  breakpoints.minWidths,
) as (keyof typeof breakpoints.minWidths)[];
const breakpointWidths = Object.values(breakpoints.minWidths).sort(
  (a, b) => a - b,
);
const { minWidths } = breakpoints;

/**
 * This hook returns the name of the breakpoint that best matches the current
 * viewport size.
 */
function useBreakpoint() {
  const { width } = useViewportSize();
  const currBreakpointWidth = breakpointWidths.reduce(
    (prev, curr) => ((width || 0) >= curr ? curr : prev),
    breakpointWidths[0],
  );
  const currBreakpoint = breakpointKeys.find(
    (key) => minWidths[key] === currBreakpointWidth,
  );

  return currBreakpoint;
}

export default useBreakpoint;
