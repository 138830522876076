/** ******************************************************************************
Update Vehicle
 * uses the /appId/vehicle/post endpoint to  update an applications associated vehicle 
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPUpdateVehicleRes,
  LOSAPIResponse,
  VehiclePurchaseSource,
} from "./client.types";

/**
 * Updates/sets an application's associated vehicle
 * @param applicationId loan application ID for the current application (string)
 * @param vehicle vehicle properties that are to be updated. (updating the vin deletes the current vehicle and creates a new one)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateVehicle: (
  applicationId: string,
  vehicle: {
    vin?: string;
    mileage?: number;
    trim?: string;
    usedStatus?: string;
    salePriceCents?: number;
    purchaseSource?: VehiclePurchaseSource;
    pickupDate?: string;
    make?: string;
    model?: string;
    year?: number;
  },
) => LOSAPIResponse<HTTPUpdateVehicleRes> = async (
  applicationId,
  {
    vin,
    mileage,
    trim,
    usedStatus,
    salePriceCents,
    pickupDate,
    purchaseSource,
    make,
    model,
    year,
  },
) => {
  const response = await makeRequest<HTTPUpdateVehicleRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).vehicle.post,
    {
      body: {
        vin,
        mileage,
        trim,
        usedStatus,
        salePriceCents,
        make,
        model,
        year,
        purchaseSource,
        ...(pickupDate && { deliveryDate: pickupDate }),
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default updateVehicle;
