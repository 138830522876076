import { ExperimentsConfig } from "#services/ExperimentService/experiments.types";

export const experimentsConfigBase = {
  "select-offer-copy": {
    events: ["saw copy", "converted", "abandoned"] as const, // describe behaviors you'd like to measure
    buckets: [
      {
        bucketName: "control", // put something more descriptive here
        weight: 1,
      },
      {
        bucketName: "variant-1", // put something more descriptive here
        weight: 1,
      },
      {
        bucketName: "variant-2", // put something more descriptive here
        weight: 1,
      },
    ],
  },
  "select-offer-copy-2": {
    events: ["do copy", "ya", "ok"] as const, // describe behaviors you'd like to measure
    buckets: [
      {
        bucketName: "blue", // put something more descriptive here
      },
      {
        bucketName: "hw-1", // put something more descriptive here
      },
      {
        bucketName: "variant-2", // put something more descriptive here
      },
    ],
  },
};

// Extracts the event names from the config

export const experimentsConfig: ExperimentsConfig = experimentsConfigBase;

type EventConfig = {
  [key in keyof typeof experimentsConfigBase]: typeof experimentsConfigBase[key]["events"];
};

export const eventConfig = Object.entries(
  experimentsConfig,
).reduce<EventConfig>((acc, [experiment, config]) => {
  if (!config.events) {
    return acc;
  }

  return {
    ...acc,
    [experiment]: config.events,
  };
}, {} as EventConfig);
