/** ******************************************************************************
Setup Autopay
 * creates a recurring payment
 ******************************************************************************* */

import { HTTPSetupAutopay, PaymentSetting } from "#api.lms/client.types";
import { PaymentDayOfMonth } from "#api.lms/lmsDto.types";
import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

const setupAutopay: ({
  loanId,
  dayOfMonth,
  paymentMethodId,
  overrideAmountCents,
  paymentSetting,
}: {
  loanId: string;
  dayOfMonth: PaymentDayOfMonth;
  paymentMethodId: string;
  overrideAmountCents?: number;
  paymentSetting?: PaymentSetting;
}) => Promise<{
  response: null | HTTPResponse<HTTPSetupAutopay>;
  error: null | HTTPResponse<HTTPSetupAutopay>;
}> = async ({
  loanId,
  dayOfMonth,
  paymentMethodId,
  overrideAmountCents,
  paymentSetting,
}) => {
  const response = await makeRequest<HTTPSetupAutopay>(
    HTTPMethod.POST,
    restPaths.payments(loanId).setupAutopay,
    {
      body: {
        dayOfMonth,
        paymentMethodId,
        overrideAmountCents: overrideAmountCents || 0,
        paymentSetting:
          paymentSetting || (overrideAmountCents ? "Override" : "AmountDue"),
      },
    },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default setupAutopay;
