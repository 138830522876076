import Cookies from "js-cookie";

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { TrackingPayload } from "./client.types";

/**
 * Tracks a FE defined event
 * @param payload payload for event
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const trackEvent = async (payload: TrackingPayload) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const anonymousId = Cookies.get("ajs_anonymous_id") as string;

  if (!anonymousId) {
    return;
  }

  await makeRequest<{ status: string }>(
    HTTPMethod.POST,
    restPaths.tracking.post,
    {
      body: {
        anonymousId,
        ...payload,
      },
    },
    false,
  );
};

export default trackEvent;
