import moment from "moment";
import { boolean, date, number, object, ref, string, StringSchema } from "yup";
import { AnyObject } from "yup/lib/types";

import { USStateInfo } from "#constants/allUSStates";
import {
  centsToDollarString,
  parseCentsFromCurrencyInputString,
} from "#util/number/number";

export const validateStateAbbreviations = (usStates: USStateInfo[]) =>
  string()
    .length(2, () => "State must be exactly two characters")
    .required()
    .test("state", "This state is not supported by Tenet yet", (value) => {
      const state = usStates.find(
        (usState) => usState.abbreviation === value?.toUpperCase(),
      );
      if (value && state?.supportsDirectPurchase && state?.supportsRefinance) {
        return true;
      }

      return false;
    });

export const validStateAbbreviation = (usStates: USStateInfo[]) =>
  string()
    .length(2)
    .test("real-state", "Please enter a valid state abbreviation", (value) => {
      const state = usStates.find(
        (usState) => usState.abbreviation === value?.toUpperCase(),
      );

      return !!state;
    });

// MT, 6/6/23 - We only want 5 digit zip codes so all DB records are consistent. Previously, there were both 5 and 9 digit in the DB
const usPostalCodeRegEx = /^\d{5}$/;
const dateOfBirthRegEx =
  /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/;
const ssnRegEx = /^\d{3}-\d{2}-\d{4}$/;
const vinLength = 17;
const bankAccountRegEx = /\W*\d{8,17}\b/;
const routingNumberLength = 9;
const phoneNumberLength = 10;

export const checkedRequiredValidator = boolean()
  .required("You must accept to continue.")
  .oneOf([true], "You must accept to continue.");

export const emailValidator = string().email("Please enter a valid email.");
export const emailRequiredValidator = emailValidator.required(
  "Please enter a valid email.",
);

export const loginPasswordRequiredValidator = string().required(
  "Please enter your password.",
);

export const passwordValidator = string()
  .min(8, "Password must be at least 8 characters long.")
  .matches(/[A-Z]+/, "Password must contain at least one uppercase letter")
  .matches(/\d+/, "Password must contain at least one number");

export const passwordRequiredValidator = passwordValidator.required(
  "Please enter a password.",
);

export const phoneValidator = string()
  .min(phoneNumberLength, "Please enter a valid phone number.")
  .max(phoneNumberLength, "Please enter a valid phone number.");

export const phoneRequiredValidator = phoneValidator.transform((val) =>
  val === "" ? undefined : (val as string),
);

export const positiveIntegerValidator = number()
  .typeError("Please enter a number")
  .test(
    "Positive integer",
    "Please enter a whole number greater than 0.",
    (value?: number) => {
      if (!value) return false;

      if (value <= 0) return false;

      return value % Math.floor(value) === 0;
    },
  );

export const confirmPasswordValidator = string().oneOf(
  [ref("password"), null],
  "The password you have entered does not match the confirmation.",
);

export const confirmPasswordRequiredValidator =
  confirmPasswordValidator.required("Please re-enter your password.");

export const vinValidator = string().length(vinLength, "Must be 17 characters");

export const vinRequiredValidator = vinValidator.required();

export const dateOfBirthValidator = string()
  .matches(dateOfBirthRegEx, "Please enter a valid date of birth.")
  .test(
    "18-or-older",
    "Sorry! You must be 18 years of age or older to use Tenet",
    (value?: string) => {
      if (!value) return false;

      const [M, D, Y] = value.split("/");
      const DOB = new Date(+Y, +M - 1, +D);
      const yearsAgo = (+new Date() - +DOB) / 1000 / 60 / 60 / 24 / 365;

      return yearsAgo >= 18;
    },
  );
export const MigratedDateOfBirthValidator = date().test(
  "18-or-older",
  "Sorry! You must be 18 years of age or older to use Tenet",
  (value?: Date) => {
    if (!value) return false;

    const yearsAgo = (+new Date() - +value) / 1000 / 60 / 60 / 24 / 365;

    return yearsAgo >= 18;
  },
);
export const moveInDateValidator = date()
  .typeError("Month and year is required (e.g. July 2020)")
  .test(
    "is-previous-date",
    "Please enter a date earlier than today",
    (value?: Date) => {
      if (!value) return false;

      const today = moment();
      const inputDate = moment(value);
      // today.diff(inputDate) will return negative if today is earlier than inputDate
      return today.diff(inputDate) > 0;
    },
  );
export const usPostalCodeValidator = string().matches(
  usPostalCodeRegEx,
  "Please enter a valid postal code.",
);
export const usPostalCodeRequiredValidator = usPostalCodeValidator.required(
  "Please enter a valid postal code.",
);

export const currencyStringValidator = string().test(
  "test-cents-number-conversion",
  "Please enter a valid number",
  (value) => {
    if (value) {
      const parsedValue = parseCentsFromCurrencyInputString(value);

      return !!parsedValue && !Number.isNaN(parsedValue);
    }

    return false;
  },
);
export const currencyStringRequiredValidator = currencyStringValidator.required(
  "Please enter an amount.",
);

export const currencyValueValidator = (
  min: number,
): StringSchema<string | undefined, AnyObject, string | undefined> =>
  string()
    .typeError("Please enter an amount.")
    .required("Please enter an amount.")
    .test(
      "test-cents-number-value",
      `Please enter an amount greater than $${(min / 100).toFixed(2)}.`,
      (value) => {
        if (value) {
          /** MT, 2/27/23 - Entering a price ending in ".00" results in value coming in
           * as a cents integer, not a Currency string. This guards for that scenario.
           * (I don't love this solution, but it works and I was chasing this for too long)
           * */
          const parsedValue = !value.startsWith("$")
            ? Number(value)
            : parseCentsFromCurrencyInputString(value);
          return (
            typeof parsedValue !== "undefined" &&
            !Number.isNaN(parsedValue) &&
            parsedValue >= min
          );
        }
        return false;
      },
    );

export const maxCurrencyValueValidator = (
  max: number,
): StringSchema<string | undefined, AnyObject, string | undefined> =>
  string()
    .typeError("Please enter an amount.")
    .required("Please enter an amount.")
    .test(
      "test-cents-number-value",
      `Please enter an amount less than $${(max / 100).toFixed(2)}.`,
      (value) => {
        if (value) {
          /** MT, 2/27/23 - Entering a price ending in ".00" results in value coming in
           * as a cents integer, not a Currency string. This guards for that scenario.
           * (I don't love this solution, but it works and I was chasing this for too long)
           * */
          const parsedValue = !value.startsWith("$")
            ? Number(value)
            : parseCentsFromCurrencyInputString(value);
          return (
            typeof parsedValue !== "undefined" &&
            !Number.isNaN(parsedValue) &&
            parsedValue <= max
          );
        }
        return false;
      },
    );

export const minMaxCurrencyValueValidator = (
  min: number,
  max: number,
  lowErrorMessage?: string,
  highErrorMessage?: string,
): StringSchema<string | undefined, AnyObject, string | undefined> => {
  const lowErrMessage =
    lowErrorMessage ||
    `Please enter an amount less than $${(max / 100).toFixed(2)}.`;
  const highErrMessage =
    highErrorMessage ||
    `Please enter an amount greater than $${(min / 100).toFixed(2)}.`;
  return string()
    .typeError("Please enter an amount.")
    .required("Please enter an amount.")
    .test("test-cents-number-value-max", lowErrMessage, (value) => {
      if (value) {
        /** MT, 2/27/23 - Entering a price ending in ".00" results in value coming in
         * as a cents integer, not a Currency string. This guards for that scenario.
         * (I don't love this solution, but it works and I was chasing this for too long)
         * */
        const parsedValue = !value.startsWith("$")
          ? Number(value)
          : parseCentsFromCurrencyInputString(value);
        return (
          typeof parsedValue !== "undefined" &&
          !Number.isNaN(parsedValue) &&
          parsedValue <= max
        );
      }
      return false;
    })
    .test("test-cents-number-value-min", highErrMessage, (value) => {
      if (value) {
        /** MT, 2/27/23 - Entering a price ending in ".00" results in value coming in
         * as a cents integer, not a Currency string. This guards for that scenario.
         * (I don't love this solution, but it works and I was chasing this for too long)
         * */
        const parsedValue = !value.startsWith("$")
          ? Number(value)
          : parseCentsFromCurrencyInputString(value);
        return (
          typeof parsedValue !== "undefined" &&
          !Number.isNaN(parsedValue) &&
          parsedValue >= min
        );
      }
      return false;
    });
};

export const autopayAmountCurrencyValueValidator = (
  min: number,
): StringSchema<string | undefined, AnyObject, string | undefined> =>
  string()
    .typeError("Please enter an amount.")
    .required("Please enter an amount.")
    .test(
      "test-cents-number-value",
      `Please enter an amount greater than monthly payment of ${centsToDollarString(
        min,
      )}`,
      (value) => {
        if (value) {
          /** MT, 2/27/23 - Entering a price ending in ".00" results in value coming in
           * as a cents integer, not a Currency string. This guards for that scenario.
           * (I don't love this solution, but it works and I was chasing this for too long)
           * */
          const parsedValue = !value.startsWith("$")
            ? Number(value)
            : parseCentsFromCurrencyInputString(value);
          return (
            typeof parsedValue !== "undefined" &&
            !Number.isNaN(parsedValue) &&
            parsedValue >= min
          );
        }
        return false;
      },
    );

export const stateObjectValidator = object().nullable();

export const stateObjectRequiredValidator = stateObjectValidator.required(
  "Please select a US state.",
);

export const usSsnValidator = string().matches(
  ssnRegEx,
  "Please enter a valid SSN.",
);

export const usSsnRequiredValidator = usSsnValidator.required(
  "Please enter a valid SSN.",
);

export const bankAccountValidator = string().matches(
  bankAccountRegEx,
  "Please enter a vaid bank account number.",
);

export const bankAccountRequiredValidator = bankAccountValidator.required(
  "Please enter a vaid bank account number.",
);

export const routingNumberValidator = string()
  .min(routingNumberLength)
  .max(routingNumberLength);

export const routingNumberRequiredValidator = routingNumberValidator.required();
