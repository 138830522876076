import { forwardRef, useState } from "react";
import NumberFormat from "react-number-format";

import Input from "#components/base/Input/Input";

import { Props } from "./CurrencyInput.types";

const CurrencyInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      valueInCents,
      maximumInDollars,
      placeholder,
      onNumberChange,
      ...otherProps
    },
    ref,
  ) => {
    const [dollarAmount, setDollarAmount] = useState(
      typeof valueInCents === "number" ? valueInCents / 100 : null,
    );

    return (
      <NumberFormat
        customInput={Input}
        getInputRef={ref}
        thousandSeparator
        prefix="$"
        decimalScale={2}
        placeholder={placeholder}
        value={dollarAmount === 0 ? null : dollarAmount}
        onValueChange={(target) => {
          if (typeof target?.floatValue === "number") {
            const newValue =
              !!maximumInDollars && target.floatValue > maximumInDollars
                ? maximumInDollars
                : target.floatValue;
            setDollarAmount(newValue);
            onNumberChange(newValue * 100);
          } else {
            setDollarAmount(0);
            onNumberChange(0);
          }
        }}
        {...otherProps}
      />
    );
  },
);

export default CurrencyInput;
